import { RematchDispatch } from '@rematch/core';
import { RootModel } from '../../store';
import DataProductService from '../../../services/DataProductService';

export const dataDomainEffects = (dispatch: RematchDispatch<RootModel>) => ({
  async fetchDataDomains() {
    try {
      dispatch.dataDomains.fetchDataDomainRequest();
      const dataDoamins = await DataProductService.getDataDomains();
      dispatch.dataDomains.fetchDataDomainSuccess(dataDoamins);
    } catch (error: unknown) {
      dispatch.dataDomains.fetchDataDomainFailure((error as Error).toString());
    }
  },
});
