import {
  Button,
  FormField,
  Icons,
  Text,
} from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import {
  CenterContainer,
  FileContainer,
  LeftContainer,
  RightContainer,
} from './CustomFileStatus.styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (isFilePresent: boolean) => void;
  value: boolean;
}

const CustomFileStatus = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      {!isDelete && value && (
        <FileContainer>
          <LeftContainer>
            <Icons.FileText width={28} height={28} />
          </LeftContainer>
          <CenterContainer>
            <Text>{value}</Text>
            <Text>
              {t(
                'admin_create-product-selector-resource_sample_reference_file',
              )}
            </Text>
          </CenterContainer>
          <RightContainer>
            <FormField id="schemaFileDelete" size="medium">
              <Button
                icon={<Icons.TrashAlt width={28} height={28} />}
                iconOnly
                size="medium"
                value={isDelete.toString()}
                variant="transparent"
                onClick={() => {
                  setIsDelete(true);
                  onChange(true);
                }}
              />
            </FormField>
          </RightContainer>
        </FileContainer>
      )}
    </>
  );
};

export default CustomFileStatus;
