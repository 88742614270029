import axiosInstance from './DPAxios';
import { generateParams } from './utils';
import { downloadFiles } from '../utils/downloadHelper';
import { TagType } from '../state/models/dataProductsModel/types';
import { DataProductVariant } from '../utils/dataProductTypes';

interface ISearchProducts {
  keywords: string;
  limit: number;
  offset: number;
  sort: string;
  filters: { [id: string]: string };
}

const parseParams = (params: { [key: string]: string | undefined }) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((p) => {
    const value = params[p];
    if (value) {
      urlParams.append(p, value);
    }
  });
  return `${
    Array.from(urlParams.values()).length > 0 ? '?' : ''
  }${urlParams.toString()}`;
};

const DATAPRODUCT_CATALOG_PATH = '/dx-catalogue-api/catalog';
const DATAPRODUCT_OVERSIGHT_PATH = '/dx-oversight-api/oversight';
const DATAPRODUCT_EXPORTER_PATH = '/dx-xporter-api/exporter';
const DATAPRODUCT_MANAGEMENT_PATH = '/dx-data-product-management-api';
const PYTHON_API_PATH = '/dx-python-apis';
const STATUS_200 = 200;

const searchDataProducts = async ({
  keywords,
  limit,
  offset,
  sort,
  filters,
}: ISearchProducts) => {
  const result = await axiosInstance.get(
    `${DATAPRODUCT_CATALOG_PATH}/search?${generateParams({
      Keywords: keywords,
      Limit: limit,
      Offset: offset,
      Sort: sort,
      ...filters,
    })}`,
  );

  return {
    totalCount: result?.data?.totalCount || 0,
    dataProducts: result?.data?.dataProducts || [],
  };
};

interface ICurrentProduct {
  id: string;
  version?: string;
  referral?: string;
}

const getCurrentDataProduct = async ({
  id,
  version,
  referral,
}: ICurrentProduct) => {
  const result = await axiosInstance.get(
    `${DATAPRODUCT_CATALOG_PATH}/item/${id}${parseParams({
      version,
      referral,
    })}`,
  );
  return result?.data?.dataProduct || {};
};

const getCurrentTableProduct = async (dataProductName: string) => {
  try {
    const result = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/tableDetails/${dataProductName}`,
    );
    return result?.data || {};
  } catch (error) {
    return [];
  }
};

const resetCacheProduct = async (name: string) => {
  await axiosInstance.get(`${DATAPRODUCT_CATALOG_PATH}/item/${name}`, {
    headers: {
      'clear-cache': 'True',
    },
  });
};

const getDPQueries = async (dataProductName: string, version?: string) => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/queries/${dataProductName}${parseParams({
        version,
      })}`,
    );
    return response?.data || [];
  } catch (error) {
    return [];
  }
};

const getEndpointSchema = async (
  dataProductName: string,
  endpointPath: string,
  version?: string,
) => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/schema/${dataProductName}/${endpointPath}${parseParams(
        { version },
      )}`,
    );
    return response.data;
  } catch (error) {
    return {};
  }
};

const downloadQueries = async (
  dataProductName: string,
  endpoints: number[] = [],
  version?: string,
) => {
  const endpointsParsed = window.btoa(endpoints.join(','));
  const response = await axiosInstance.get(
    `${DATAPRODUCT_EXPORTER_PATH}/${dataProductName}/${endpointsParsed}${parseParams(
      {
        version,
      },
    )}`,
    { headers: { 'Content-Type': 'text/csv' }, data: {} },
  );
  return downloadFiles(response.data, dataProductName);
};

const getDataDomains = async () => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/glossary`,
    );
    return response?.data?.terms.filter(
      (t: TagType) => t.group === 'Data Domain',
    );
  } catch (error) {
    return [];
  }
};

const getDataDomainSubscriptions = async () => {
  try {
    const response = await axiosInstance.get(
      `${PYTHON_API_PATH}/subscribe-data-domains`,
    );
    return response.data || [];
  } catch (error) {
    return [];
  }
};

const saveSubscriptions = async (
  dataDomainName: string,
  isSubscribed: boolean,
) => {
  try {
    const payload = {
      [dataDomainName]: isSubscribed,
    };
    const response = await axiosInstance.post(
      `${PYTHON_API_PATH}/subscribe-data-domains`,
      payload,
    );
    return response.status === STATUS_200;
  } catch (error) {
    return false;
  }
};

const getRequests = async (fromDate: number) => {
  const nowInSeconds = Math.round(Date.now() / 1000);
  const response = await axiosInstance.get(
    `${DATAPRODUCT_OVERSIGHT_PATH}/dataproducts/usage?startDate=${Math.floor(
      fromDate,
    )}&endDate=${nowInSeconds}&amount=5`,
  );
  return response?.data || { totalCount: 0, dataProducts: [] };
};

const getVersions = async (dataProductName: string) => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/versions/${dataProductName}`,
    );
    return response?.data?.versions || [];
  } catch (error) {
    return [];
  }
};

const getTags = async () => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/tags`,
    );

    return response?.data?.tags || [];
  } catch (error) {
    return [];
  }
};

export type accessTypeType = 'public' | 'private' | 'protected';

interface BaseDataProductProps {
  name: string;
  businessName: string;
  accessType: accessTypeType;
  description: string;
  version: string;
  overrideVersion?: boolean;
  owner: string;
  expert: string;
  KeycloakClientId: string;
  tags: string;
  terms: string;
  type: DataProductVariant;
  customMetadata?: string;
}

export interface DataProductAPIProps extends BaseDataProductProps {
  endpoints: string;
  schemaFiles: { file: File; name: string }[];
  policies: string;
  openConfigurationUrl?: string;
  prebuiltQueriesFile?: File;
  customHeaders?: string;
}

export interface DataProductResourceProps extends BaseDataProductProps {
  url: string;
  schemaFiles: File;
  sampleFileName: string | null;
  schemaFileDelete: boolean;
  showRequestAccessButton: boolean;
}

export interface DataProductStreamingProps extends BaseDataProductProps {
  uri: string;
  topicName: string;
  retentionTime: string;
}

export interface DataProductEventProps extends DataProductStreamingProps {
  pollTime: string;
}

export interface DataProductBatchProps extends BaseDataProductProps {
  dataVolume: number;
  batchSize: number;
  batchFrequency: string;
  dataSource: string;
}

type valuesType =
  | DataProductAPIProps
  | DataProductResourceProps
  | DataProductStreamingProps
  | DataProductEventProps
  | DataProductBatchProps;

export const createFormData = (
  values: valuesType,
  type: DataProductVariant,
) => {
  const formData = new FormData();
  formData.append('businessName', values.businessName);
  formData.append('description', values.description);
  formData.append('version', values.version);
  values.overrideVersion &&
    formData.append('overrideVersion', `${values.overrideVersion}`);
  formData.append('owner', values.owner);
  formData.append('expert', values.expert);
  formData.append('tags', values.tags);
  formData.append('terms', values.terms);
  formData.append('KeycloakClientId', values.KeycloakClientId);
  values.customMetadata &&
    formData.append('customMetadata', values.customMetadata);

  switch (type) {
    case 'API':
      formData.append('accessType', values.accessType);
      formData.append('endpoints', (values as DataProductAPIProps).endpoints);
      (values as DataProductAPIProps).schemaFiles.forEach((sF) =>
        formData.append('schemaFiles', sF.file, sF.name),
      );
      (values as DataProductAPIProps)?.policies?.length > 0 &&
        formData.append('policies', (values as DataProductAPIProps).policies);
      const openConfigurationUrlIn = (values as DataProductAPIProps)
        .openConfigurationUrl;
      openConfigurationUrlIn &&
        formData.append('openIdConfigurationUrl', openConfigurationUrlIn);
      const prebuiltQueriesFile = (values as DataProductAPIProps)
        .prebuiltQueriesFile;
      prebuiltQueriesFile &&
        formData.append(
          'prebuiltQueriesFile',
          prebuiltQueriesFile,
          'prebuiltQueriesFile.json',
        );
      const customHeaders = (values as DataProductAPIProps)?.customHeaders;
      customHeaders && formData.append('customHeaders', customHeaders);
      break;
    case 'Resource':
      formData.append('URL', (values as DataProductResourceProps).url);
      if ((values as DataProductResourceProps).schemaFiles) {
        formData.append(
          'schemaFiles',
          (values as DataProductResourceProps).schemaFiles,
        );
      }
      if (
        `${(values as DataProductResourceProps).schemaFileDelete}` === 'true'
      ) {
        formData.append(
          'schemaFileDelete',
          `${(values as DataProductResourceProps).schemaFileDelete}`,
        );
      }
      formData.append(
        'showRequestAccessButton',
        (
          values as DataProductResourceProps
        ).showRequestAccessButton?.toString() || 'false',
      );
      break;
    case 'Streaming':
      formData.append('Uri', (values as DataProductStreamingProps).uri);
      formData.append(
        'topicName',
        (values as DataProductStreamingProps).topicName,
      );
      formData.append(
        'retentionTime',
        (values as DataProductStreamingProps).retentionTime,
      );
      break;
    case 'Event':
      formData.append('Uri', (values as DataProductEventProps).uri);
      formData.append('topicName', (values as DataProductEventProps).topicName);
      formData.append(
        'retentionTime',
        (values as DataProductEventProps).retentionTime,
      );
      formData.append('pollTime', (values as DataProductEventProps).pollTime);
      break;
    case 'Batch':
      formData.append('URL', (values as DataProductResourceProps).url);
      formData.append(
        'dataVolume',
        (values as DataProductBatchProps).dataVolume.toString(),
      );
      formData.append(
        'batchSize',
        (values as DataProductBatchProps).batchSize.toString(),
      );
      formData.append(
        'batchFrequency',
        (values as DataProductBatchProps).batchFrequency,
      );
      formData.append(
        'dataSource',
        (values as DataProductBatchProps).dataSource,
      );
      const numberOfPages =
        (values as DataProductBatchProps).dataVolume /
        (values as DataProductBatchProps).batchSize;
      formData.append('numberOfPages', numberOfPages.toString());
      break;
    default:
      break;
  }

  return formData;
};

const createDataProduct = async (values: valuesType) => {
  const formData = createFormData(values, values.type);
  return axiosInstance.post(
    `${DATAPRODUCT_MANAGEMENT_PATH}/CreateDataProduct/${values.type.toLowerCase()}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'text/plain',
      },
    },
  );
};

const updateDataProduct = async (values: valuesType) => {
  const formData = createFormData(values, values.type);
  formData.delete('KeycloakClientId');
  return axiosInstance.post(
    `${DATAPRODUCT_MANAGEMENT_PATH}/update/${values.name}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'text/plain',
      },
    },
  );
};

const deleteDataProduct = async (name: string) => {
  await axiosInstance.delete(`${DATAPRODUCT_MANAGEMENT_PATH}/delete/${name}`);
};

const getOwnDataProducts = async ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}) => {
  const result = await axiosInstance.get(
    `${DATAPRODUCT_CATALOG_PATH}/user/dataProducts?limit=${limit}&offset=${offset}`,
  );
  return {
    totalCount: result?.data?.totalCount || 0,
    dataProducts:
      // @ts-ignore
      result?.data?.dataProducts.map((dp) => ({
        ...dp,
      })) || [],
  };
};

const getAdminDataProduct = async ({ name }: { name: string }) => {
  const result = await axiosInstance.get(
    `${DATAPRODUCT_MANAGEMENT_PATH}/dataproduct/${name}`,
  );
  return result?.data || {};
};

const getReferralLink = async (dataProductName: string) => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/share/social/${dataProductName}`,
    );
    return response?.data?.referral;
  } catch (error) {
    return '';
  }
};

const getResourceFile = async (dataProductName: string) => {
  try {
    const response = await axiosInstance.get(
      `${DATAPRODUCT_CATALOG_PATH}/schema/download/${dataProductName}`,
    );
    return response?.data;
  } catch (error) {
    return '';
  }
};

export interface AccessRequest {
  businessName: string;
  dpOwnerEmail: string;
  businessJustification: string;
  link: string;
}

const sendAccessRequest = async (
  dataProductName: string,
  values: AccessRequest,
) => {
  return axiosInstance.post(
    `${DATAPRODUCT_CATALOG_PATH}/share/email/access/${dataProductName}`,
    values,
  );
};

export interface Feedback {
  kind: string;
  feedback: string;
  contact: boolean;
}

const sendFeedback = async (values: Feedback) => {
  return axiosInstance.post(`${DATAPRODUCT_CATALOG_PATH}/feedback`, values);
};

export interface Issue {
  businessName: string;
  dpOwnerEmail: string;
  issueDescription: string;
  link: string;
  issueType: string[];
}

const reportIssue = async (dataProductName: string, values: Issue) => {
  return axiosInstance.post(
    `${DATAPRODUCT_CATALOG_PATH}/share/email/issue/${dataProductName}`,
    values,
  );
};

const DataProductService = {
  searchDataProducts,
  getCurrentDataProduct,
  getCurrentTableProduct,
  getDPQueries,
  getEndpointSchema,
  downloadQueries,
  getDataDomains,
  getRequests,
  createDataProduct,
  deleteDataProduct,
  getOwnDataProducts,
  getAdminDataProduct,
  updateDataProduct,
  getVersions,
  getTags,
  resetCacheProduct,
  getReferralLink,
  sendFeedback,
  sendAccessRequest,
  reportIssue,
  getResourceFile,
  saveSubscriptions,
  getDataDomainSubscriptions,
};

export default DataProductService;
