import {
  CatalogTableType,
  DataProductsState,
  DataProductType,
  DownloadQuery,
  TagType,
} from './types';
const reducers = {
  setDataProducts(
    state: DataProductsState,
    {
      dataProducts,
      totalItems,
    }: {
      dataProducts: DataProductType[];
      totalItems: number;
    },
  ) {
    return {
      ...state,
      dataProducts,
      totalItems,
    };
  },
  setCacheDataProducts(
    state: DataProductsState,
    {
      dataProducts,
      totalItems,
      rowsPerPage,
    }: {
      dataProducts: DataProductType[];
      totalItems: number;
      rowsPerPage: number;
    },
  ) {
    return {
      ...state,
      cache: { ...state.cache, dataProducts, totalItems, rowsPerPage },
    };
  },
  setIsLoading(state: DataProductsState, isLoading: boolean) {
    return {
      ...state,
      isLoading,
    };
  },
  setHasError(state: DataProductsState, hasError: boolean) {
    return {
      ...state,
      hasError,
    };
  },

  setCurrentDataProduct(
    state: DataProductsState,
    currentDataProduct: DataProductType,
  ) {
    return {
      ...state,
      currentDataProduct,
    };
  },

  setQueries(state: DataProductsState, queries: DownloadQuery[]) {
    return {
      ...state,
      currentDataProduct: { ...state.currentDataProduct, queries },
    };
  },
  setTerms(state: DataProductsState, terms: TagType[]) {
    return {
      ...state,
      terms,
    };
  },
  setTags(state: DataProductsState, tags: string[]) {
    return {
      ...state,
      tags,
    };
  },

  setLikes(
    state: DataProductsState,
    { likes, userHasLiked }: { likes: number; userHasLiked: boolean },
  ) {
    return {
      ...state,
      currentDataProduct: {
        ...state.currentDataProduct,
        likes,
        userHasLiked,
      },
    };
  },

  setCatalogTable(state: DataProductsState, catalogTable: CatalogTableType) {
    return {
      ...state,
      catalogTable,
    };
  },
};
export default reducers;
