import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';

const useSubscription = () => {
  const dispatch = useDispatch();
  const { items: subscribedDomains } = useSelector(
    (state: RootState) => state.subscriptions,
  );

  useEffect(() => {
    dispatch.subscriptions.fetchSubscriptions();
  }, []);

  // Subscribe to a domain
  const subscribe = (domain: string) => {
    dispatch.subscriptions.subcribeDataDomain(domain);
  };

  // Unsubscribe from a domain
  const unsubscribe = (domain: string) => {
    dispatch.subscriptions.unSubcribeDataDomain(domain);
  };

  return { subscribedDomains, subscribe, unsubscribe };
};

export default useSubscription;
