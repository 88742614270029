import { Icons, Label } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { Container } from './TypeBadge.styles';
import { DataProductVariant } from '../../utils/dataProductTypes';

interface Props {
  type: DataProductVariant;
  iconVisible?: boolean;
}

const options = {
  API: { label: 'API', icon: <Icons.DesignSystem height={14} width={14} /> },
  Event: { label: 'Event', icon: <Icons.Calendar height={14} width={14} /> },
  Resource: {
    label: 'Resource',
    icon: <Icons.FileText height={14} width={14} />,
  },
  Streaming: {
    label: 'Streaming',
    icon: <Icons.LineChartSolid height={14} width={14} />,
  },
  Batch: {
    label: 'Batch',
    icon: <Icons.RowsSolid height={14} width={14} />,
  },
};

const TypeBadge: React.FC<Props> = ({ type, iconVisible = true }) => {
  const { label, icon } = options[type] || options['API'];
  return (
    <Container>
      {iconVisible && icon}
      <Label color={'normal'}>{label}</Label>
    </Container>
  );
};

export default TypeBadge;
