import { DataProductType } from '../../../../../../../../../state/models/dataProductsModel/types';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../state/store';
import {
  Button,
  ButtonVariant,
  FormField,
  Heading,
  Modal,
  TextArea,
} from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import DataProductService from '../../../../../../../../../services/DataProductService';
import image from '../../../../../../../../../assets/images/banner-image.jpg';
import { AxiosError } from 'axios';
import RequestAccessErrorModal from './RequestAccessErrorModal';
import RequestAccessSuccessModal from './RequestAccessSuccessModal';
import {
  Actions,
  Content,
  ModalContent,
  StyledImage,
  StyledText,
} from './RequestAccessModal.styles';

interface Props {
  dataProduct: DataProductType;
  onCancel: () => void;
}

const RequestAccessModal = ({ dataProduct, onCancel }: Props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => {
    return state.user;
  });
  const [justification, setJustification] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSend = async () => {
    setIsLoading(true);
    try {
      await DataProductService.sendAccessRequest(dataProduct.name, {
        businessName: dataProduct.businessName,
        dpOwnerEmail: dataProduct.owner.email,
        businessJustification: justification,
        link: window.location.href,
      });
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setIsError(true);
      }
    }
  };

  const actions = [
    {
      label: t('modal_generic-cancel'),
      action: () => {
        onCancel();
      },
      props: {
        variant: 'outlined' as ButtonVariant,
        disabled: isLoading,
      },
      key: 'cancel',
    },
    {
      label: t('modal_generic-send'),
      action: onSend,
      props: { disabled: isLoading || !justification },
      Key: 'send',
    },
  ];

  if (isSuccess) {
    return <RequestAccessSuccessModal onClose={onCancel} />;
  }
  if (isError) {
    return <RequestAccessErrorModal onClose={onCancel} />;
  }
  return (
    <Modal
      open
      onClose={onCancel}
      title={t('request_access_modal-header', {
        product: dataProduct.businessName,
      })}
      bodyPadding={false}
      showHeader={false}
      showFooter={false}
      width="60vw"
      closable={false}
    >
      <ModalContent>
        <StyledImage src={image} alt="Request Access Modal form" />
        <Content>
          <Heading level={2} bold gutter>
            {t('request_access_modal-header', {
              product: dataProduct.businessName,
            })}
          </Heading>

          <StyledText>
            {t('request_access_modal-body_owner', {
              name: dataProduct.owner?.name,
              email: dataProduct.owner?.email,
            })}
          </StyledText>
          <StyledText>
            <Trans
              t={t}
              i18nKey="request_access_modal-body_user"
              values={{ name: user.name, email: user.email }}
            />
          </StyledText>
          <StyledText>
            {t('request_access_modal-body_justification')}
          </StyledText>
          <FormField
            label={t('request_access_modal-body_justification_label')}
            mandatory
          >
            <TextArea
              value={justification}
              onChange={(e) => setJustification(e.target.value)}
              placeholder={t(
                'request_access_modal-body_justification_placeholder',
              )}
              rows={5}
              disabled={isLoading}
            />
          </FormField>
          <Actions>
            {actions.map((action) => (
              <Button
                key={action.key}
                onClick={action.action}
                disabled={action.props.disabled}
                className={`btn ${action.props.variant}`}
                variant={action.props.variant}
              >
                {action.label}
              </Button>
            ))}
          </Actions>
        </Content>
      </ModalContent>
    </Modal>
  );
};

export default RequestAccessModal;
