import {
  Divider,
  Pagination,
  Select,
  Text,
} from '@sede-x/shell-ds-react-framework';
import { useEffect, useRef } from 'react';
import Item, { ItemTypes } from '../Item/Item';
import {
  HeaderBusinessName,
  HeaderContainer,
  HeaderIconPlaceholder,
  HeaderLikes,
  HeaderType,
  ListContainer,
  PaginationContainer,
  PaginationSizeContainer,
  SelectBox,
} from './List.styles';
import { getPageInfo } from '../../utils/paginationFunctions';
import EmptyState from '../EmptyState/EmptyState';
import { generateOptions } from '../../utils/optionGenerator';
import { useTranslation } from 'react-i18next';
import ItemAlt from '../Item/ItemAlt';
import { SelectedView } from '../../sections/FiltersComponent/FiltersComponent';

const List = ({
  dataProducts,
  totalItems,
  searchText,
  page,
  maxRowsPerPage,
  onPageChange,
  onPaginationChange,
  selectedView,
}: {
  dataProducts: ItemTypes[];
  totalItems: number;
  searchText: string;
  page: number;
  maxRowsPerPage: number;
  onPageChange: (page: number) => void;
  onPaginationChange: (newValue: number) => void;
  selectedView: SelectedView;
}) => {
  const { t } = useTranslation();
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (page !== 1 && listContainerRef.current) {
      listContainerRef.current.scrollIntoView();
    }
  }, [page]);

  const currentPageItems = dataProducts.length;

  const renderItems = () => {
    if (totalItems === 0) {
      return <EmptyState keywords={searchText} />;
    }

    if (selectedView === 'List') {
      return dataProducts.map((item: ItemTypes) => (
        <Item item={item} key={item.id} />
      ));
    }

    return (
      <>
        <HeaderContainer>
          <HeaderIconPlaceholder />
          <HeaderBusinessName>{t('dashboard_name')}</HeaderBusinessName>
          <HeaderType>{t('dashboard_type')}</HeaderType>
          <HeaderLikes>{t('dashboard_likes')}</HeaderLikes>
        </HeaderContainer>
        <Divider size="small" gutter="none" />
        {dataProducts.map((item: ItemTypes) => (
          <ItemAlt item={item} key={item.id} />
        ))}
      </>
    );
  };

  const renderPagination = () => {
    if (totalItems <= maxRowsPerPage) {
      return null;
    }

    return (
      <PaginationContainer>
        <PaginationSizeContainer>
          <Text>{t('items_per_page') + ' :'}</Text>
          <SelectBox>
            <Select
              onChange={onPaginationChange}
              style={{ minWidth: '85px' }}
              allowClear={false}
              value={maxRowsPerPage}
              options={generateOptions([10, 20, 50, 100])}
            />
          </SelectBox>
          <Text>
            {getPageInfo(page, maxRowsPerPage, totalItems, currentPageItems)}
          </Text>
        </PaginationSizeContainer>
        <Pagination
          current={page}
          defaultPageSize={maxRowsPerPage}
          total={totalItems}
          onChange={onPageChange}
          data-testid="pagination-component"
        />
      </PaginationContainer>
    );
  };

  return (
    <ListContainer key={maxRowsPerPage} ref={listContainerRef}>
      {renderItems()}
      {renderPagination()}
    </ListContainer>
  );
};

export default List;
