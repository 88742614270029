import { PageLayout } from '@sede-x/glass-design-library';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContainer } from '../../globalStyles';

import { headerConfig, LayoutProps } from './common';
import SearchHeader from '../../sections/SearchHeader/SearchHeader';
import routes from '../../routes/routes';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import { ContentContainer, RightContainer } from './Layout.styles';
import { IGlobalHeader } from '@sede-x/glass-design-library';
import { GlassExplorer } from '@sede-x/glass-explorer-lib';
import { useTheme } from 'styled-components';

const pages = {
  admin: routes.admin,
  home: routes.home,
  datamap: routes.datamap,
  insights: routes.insights,
  mySubscriptions: routes.mySubscriptions,
};

const getCurrentPage = (path: string) => {
  const location = path.split('/')?.[1];

  return (
    Object.keys(pages).find((p) => {
      return p === location;
    }) ?? 'home'
  );
};

export const getGlasshubEnv = (): IGlobalHeader['env'] => {
  if (process.env.REACT_APP_MASTERMIND_URL?.includes('sbx')) return 'sandbox';
  if (process.env.REACT_APP_MASTERMIND_URL?.includes('dev'))
    return 'development';
  if (process.env.REACT_APP_MASTERMIND_URL?.includes('qat')) return 'QAT';
  if (process.env.REACT_APP_MASTERMIND_URL?.includes('prd'))
    return 'production';
};

const Layout = ({
  variant,
  content,
  rightHeaderContent,
  padding,
  hasSearchBar = true,
  leftSideBar,
}: LayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const onHeaderClick = () => {
    navigate(routes.home);
  };
  const mapThemeName = (name: string): 'dark' | 'light' => {
    switch (name) {
      case 'rechargeLight':
        return 'light';
      case 'rechargeDark':
        return 'dark';
      default:
        return name as 'dark' | 'light';
    }
  };

  return (
    <AppContainer>
      <ContentContainer>
        <PageLayout
          globalHeader={{
            ...headerConfig(t),
            defaultTab: getCurrentPage(location.pathname),
            tabs: [
              { label: 'Home', key: 'home' },
              { label: 'My Subscriptions', key: 'mySubscriptions' },
              { label: 'Admin Panel', key: 'admin' },
              { label: 'Data Map', key: 'datamap' },
              { label: 'Insights', key: 'insights' },
            ],
            rightComponent: (
              <RightContainer>
                {hasSearchBar ? SearchHeader() : rightHeaderContent}
                <GlassExplorer
                  env={getGlasshubEnv() ?? 'production'}
                  token={sessionStorage.getItem('accessToken') ?? ''}
                  theme={mapThemeName(theme.name)}
                />
              </RightContainer>
            ),
            onChangeTab: (tab: string) =>
              navigate(pages[tab as keyof typeof pages]),
            onHeaderClick,
            env: getGlasshubEnv(),
            token: sessionStorage.getItem('accessToken') ?? '',
            hideGlassHub: true,
          }}
          variant={variant}
          content={content}
          padding={padding}
          leftSideBar={leftSideBar}
        />
        <Footer />
      </ContentContainer>
    </AppContainer>
  );
};

export default Layout;
