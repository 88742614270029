import { RematchDispatch } from '@rematch/core';
import DataProductService, {
  DataProductAPIProps,
  DataProductBatchProps,
  DataProductEventProps,
  DataProductResourceProps,
  DataProductStreamingProps,
} from '../../../services/DataProductService';
import { RootModel } from '../../store';
import { DataProductType } from '../dataProductsModel/types';
import dataProductTypes, {
  DataProductVariant,
} from '../../../utils/dataProductTypes';
import { DataProductAdminType } from './types';
import GlassProductService from '../../../services/GlassProductService';
import { LikesType } from '../dataProductsModel/effects';

interface BaseDataProductType {
  name: string;
  businessName: string;
  accessType: 'public' | 'private' | 'protected';
  description: string;
  version: string;
  owner: string;
  type: DataProductVariant;
  expert: string;
  tags: string[];
  terms: string;
  KeycloakClientId: string;
  customMetadata?: string;
}

export interface DataProductAPIType extends BaseDataProductType {
  endpoints?: { path: string; serviceURL: string; schemaFile: File }[];
  policies?: string[];
  openConfigurationUrl?: string;
  prebuiltQueriesFile?: File;
}

export interface DataProductResourceType extends BaseDataProductType {
  url?: string;
  sampleFileName?: string;
  showRequestAccessButton?: boolean;
}

export interface DataProductStreamingType extends BaseDataProductType {
  uri: string;
  topicName: string;
  retentionTime: string;
}

export interface DataProductEventType extends DataProductStreamingType {
  pollTime: string;
}

export interface DataProductBatchType extends BaseDataProductType {
  dataVolume: number;
  batchSize: number;
  batchFrequency: string;
  dataSource: string;
  numberOfPages: number;
}

type cleanedDataType =
  | DataProductAPIProps
  | DataProductResourceProps
  | DataProductStreamingProps
  | DataProductEventProps
  | DataProductBatchProps;

type cleanDataTypeValues =
  | DataProductAPIType
  | DataProductResourceType
  | DataProductStreamingType
  | DataProductEventType
  | DataProductBatchType;

const cleanData: (
  values: cleanDataTypeValues,
  type: DataProductVariant,
) => cleanedDataType = (values, type) => {
  const genericCleaning = {
    ...values,
    tags: values?.tags ? values?.tags?.join(',') : '',
    terms: values?.terms ?? '',
    KeycloakClientId: values.name,
    version: values.version || '1.0.0',
    customMetadata: values.customMetadata
      ? JSON.stringify(
          JSON.parse(values.customMetadata).map(
            (cM: { key: string; value: string; id: string }) => ({
              key: cM.key,
              value: cM.value,
            }),
          ),
        )
      : undefined,
  };
  if (type === 'API') {
    return {
      ...genericCleaning,
      endpoints:
        (values as DataProductAPIType).endpoints
          ?.map((e) =>
            JSON.stringify({
              path: e.path,
              serviceUrl: e.serviceURL,
            }),
          )
          .join(',') ?? '',
      schemaFiles:
        (values as DataProductAPIType)?.endpoints?.map((e) => ({
          file: e.schemaFile,
          name: e.schemaFile.name.includes('.graphql')
            ? `${e.path}.graphql`
            : `${e.path}-openapi.json`,
        })) ?? [],
      policies: (values as DataProductAPIType)?.policies?.join(',') ?? '',
    };
  }
  return genericCleaning as cleanedDataType;
};

const createDataProductByType = async (
  type: DataProductVariant,
  values: cleanDataTypeValues,
) => {
  const cleanedData = cleanData(values, type);
  return DataProductService.createDataProduct(cleanedData);
};

const effects = (dispatch: RematchDispatch<RootModel>) => ({
  async getOwnDataProducts({ page }: { page: number }) {
    try {
      const pageSize = 10;
      dispatch.admin.setIsLoading(true);

      const response = await DataProductService.getOwnDataProducts({
        limit: pageSize,
        offset: (page - 1) * pageSize,
      });
      const { totalCount, dataProducts } = response;

      const productNames = dataProducts.map((dP: DataProductType) => dP.name);

      const likes: LikesType[] = await GlassProductService.getLikes(
        productNames,
      ).catch(() => []);

      const enrichedDataProducts = dataProducts.map((dP: DataProductType) => {
        const likesNumber = likes?.find(
          (like: LikesType) => like.name === dP.name,
        );
        return {
          ...dP,
          likes: likesNumber?.likes ?? 0,
        };
      });

      dispatch.admin.setOwnDataProducts({
        ownDataProducts: enrichedDataProducts,
        totalItems: totalCount,
      });
      dispatch.admin.setHasError(false);
    } catch (e) {
      dispatch.admin.setHasError(true);
    } finally {
      dispatch.admin.setIsLoading(false);
    }
  },

  async getCurrentDataProduct({ name }: { name: string }) {
    try {
      dispatch.admin.setIsLoading(true);
      const dataProduct = await DataProductService.getAdminDataProduct({
        name,
      });

      const dataProductCleaned = {
        name: dataProduct.name,
        businessName: dataProduct.businessName,
        description: dataProduct.description,
        terms: dataProduct.terms?.filter(
          (term: string) => term !== 'Not labeled',
        ),
        tags: dataProduct.tags,
        version: dataProduct.version,
        KeycloakClientId: dataProduct?.keycloak?.clientId,
        url:
          dataProduct?.resourceProperties?.url ||
          dataProduct?.batchProperties?.url,
        owner: dataProduct.owner,
        expert: dataProduct.expert,
        accessType: dataProduct?.apiProperties?.accessType,
        endpoints: dataProduct?.apiProperties?.endpoints.map(
          (e: { path: string; serviceUrl: string }) => ({
            path: e.path,
            serviceURL: e.serviceUrl,
          }),
        ),
        policies: dataProduct?.apiProperties?.policies?.filter(
          (p: string) => p !== 'no-auth',
        ),
        openConfigurationUrl:
          dataProduct?.apiProperties?.openIdConfigurationUrl,
        retentionTime: `${dataProduct?.eventProperties?.retentionTime}`,
        pollTime: `${dataProduct?.eventProperties?.pollTime}`,
        uri: dataProduct?.eventProperties?.uri,
        topicName: dataProduct?.eventProperties?.topicName,
        id: dataProduct.id,
        type: dataProduct.type,
        dataVolume: dataProduct?.batchProperties?.dataVolume.toString(),
        batchSize: dataProduct?.batchProperties?.batchSize.toString(),
        batchFrequency: dataProduct?.batchProperties?.batchFrequency,
        dataSource: dataProduct?.batchProperties?.dataSource,
        numberOfPages: dataProduct?.batchProperties?.numberOfPages.toString(),
        customMetadata: dataProduct?.customMetadata
          ? JSON.stringify(
              JSON.parse(dataProduct?.customMetadata).map(
                (cM: { key: string; value: string }) => ({
                  ...cM,
                  id: cM.key,
                }),
              ),
            )
          : '[]',
        customHeaders: JSON.stringify(
          dataProduct?.apiProperties?.customHeaders,
        ),
      };

      if (
        dataProduct.type.toLowerCase() === dataProductTypes.API.toLowerCase()
      ) {
        const newEndpoints = await Promise.all(
          dataProductCleaned.endpoints.map(async (e: { path: string }) => {
            const schemaFile = await DataProductService.getEndpointSchema(
              name,
              e.path,
            );
            const schemaFileName = Object.keys(schemaFile).includes('openapi')
              ? `${e.path}-openapi.json`
              : `${e.path}.graphql`;
            const schemaFileElement = schemaFile
              ? new File([JSON.stringify(schemaFile)], schemaFileName)
              : undefined;
            return {
              ...e,
              schemaFile: schemaFileElement,
            };
          }),
        );

        const prebuiltQueriesFile = await DataProductService.getDPQueries(name);

        const dataProductCompleted = {
          ...dataProductCleaned,
          endpoints: newEndpoints,
          prebuiltQueriesFile:
            Object.keys(prebuiltQueriesFile).length > 0
              ? new File(
                  [JSON.stringify(prebuiltQueriesFile)],
                  'prebuiltQueriesFile.json',
                )
              : undefined,
        };
        dispatch.admin.setCurrentDataProduct(dataProductCompleted);
      } else {
        dispatch.admin.setCurrentDataProduct(dataProductCleaned);
      }

      if (
        dataProduct.type.toLowerCase() ===
        dataProductTypes.Resource.toLowerCase()
      ) {
        const fileName =
          dataProduct?.resourceProperties?.sampleFileName || null;
        const requestAccess =
          dataProduct?.resourceProperties?.showRequestAccessButton === 'true';

        dispatch.admin.setCurrentDataProduct({
          ...dataProductCleaned,
          schemaFileDelete: fileName,
          showRequestAccessButton: requestAccess,
        });
      }

      dispatch.admin.setHasError(false);
    } catch (e) {
      dispatch.admin.setHasError(true);
    } finally {
      dispatch.admin.setIsLoading(false);
    }
  },
  async createDataProduct(values: cleanDataTypeValues) {
    let status = null;
    try {
      dispatch.admin.setIsLoading(true);
      await createDataProductByType(values.type, values);
      dispatch.admin.setHasError(false);
      dispatch.admin.setCurrentError(undefined);
      status = 200;
    } catch (e) {
      dispatch.admin.setCurrentError(
        //@ts-ignore
        e.response?.data?.error?.message || 'An error happen',
      );
      dispatch.admin.setHasError(true);
      //@ts-ignore
      status = e?.response?.status || 500;
    } finally {
      dispatch.admin.setIsLoading(false);
    }
    return status;
  },
  async updateDataProduct(values: cleanDataTypeValues) {
    let status = null;
    try {
      dispatch.admin.setIsLoading(true);

      const cleanedData = cleanData(values, values.type);
      await DataProductService.updateDataProduct(cleanedData);
      await DataProductService.resetCacheProduct(cleanedData.name);

      dispatch.admin.setHasError(false);
      dispatch.admin.setCurrentError(undefined);
      status = 200;
    } catch (e) {
      dispatch.admin.setCurrentError(
        //@ts-ignore
        e.response?.data?.error?.message || 'An error happen',
      );
      dispatch.admin.setHasError(true);
      //@ts-ignore
      status = e?.response?.status || 500;
    } finally {
      dispatch.admin.setIsLoading(false);
    }
    return status;
  },
  async deleteDataProduct({ name }: { name: string }) {
    try {
      dispatch.admin.setIsLoading(true);
      await DataProductService.deleteDataProduct(name);
      dispatch.admin.setHasError(false);
    } catch (e) {
      dispatch.admin.setHasError(true);
    } finally {
      dispatch.admin.setIsLoading(false);
    }
  },
  cleanError() {
    dispatch.admin.setCurrentError(undefined);
    dispatch.admin.setHasError(false);
  },
  cleanCurrentDataProduct() {
    dispatch.admin.setCurrentDataProduct({} as DataProductAdminType);
  },
});

export default effects;
