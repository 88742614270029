import { Heading } from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import { CatalogTableType } from '../../../../../../state/models/dataProductsModel/types';
import { Container } from './Table.styles';

import { TableSystem } from '@sede-x/glass-design-library';
import TypeTranslation from '../../../../../../utils/i18n/TypeTranslation';
import { useTranslation } from 'react-i18next';

interface Props {
  catalogTable: CatalogTableType[];
  tags: string[];
}

const columns = (t: TypeTranslation) => [
  {
    dataIndex: 'tableName',
    key: 'tableName',
    title: t('details-batch-table_title-name'),
    width: 1,
  },
  {
    dataIndex: 'tableDescription',
    key: 'tableDescription',
    title: t('details-batch-table_title-description'),
  },
];

const AllTableColumns = (t: TypeTranslation) => [
  {
    dataIndex: 'FieldName',
    key: 'FieldName',
    title: t('details-all-table-field-name'),
    width: 1,
  },
  {
    dataIndex: 'DataType',
    key: 'DataType',
    title: t('details-all-table-data-type'),
  },
  {
    dataIndex: 'Description',
    key: 'Description',
    title: t('details-all-table-description'),
  },
];

const pageSize = 10;

const Table: React.FC<Props> = ({ catalogTable, tags }) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const showCatalogTable =
    tags.includes('UNITY') && tags.includes('SCHEMAS_WITH_TAG');
  const tableData = showCatalogTable
    ? catalogTable
    : catalogTable[0].TableColumns;
  const paginateData = (): CatalogTableType[] => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    if (Array.isArray(tableData)) {
      return tableData.slice(startIndex, endIndex) as CatalogTableType[];
    }
    return [];
  };

  return (
    <Container>
      <Heading level={2} bold gutter>
        Batch Details
      </Heading>
      <div>
        <TableSystem
          columns={showCatalogTable ? columns(t) : AllTableColumns(t)}
          data={paginateData()}
          hasHover={false}
          onPageChange={setPage}
          totalRows={tableData?.length ?? 0}
          maxRowsPerPage={pageSize}
          page={page}
          pagination
        />
      </div>
    </Container>
  );
};

export default Table;
