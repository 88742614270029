import { Option } from '@sede-x/shell-ds-react-framework';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { StyledSelect } from './TagsField.styles';
import { getFilteredTags } from '../../../utils/filterTags';

interface Props {
  onChange: (value: string[]) => void;
  value?: string[];
}

const TagsField = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const tagsData = useSelector((state: RootState) => state.dataProducts.tags);
  const tags = getFilteredTags(tagsData);

  const handleChange = (e: string[]) => {
    onChange(e.map((v) => v.toUpperCase()));
  };

  return (
    <StyledSelect
      onChange={handleChange}
      placeholder={t('admin_create-product_details_step-tags_placeholder')}
      value={value}
      mode="tags"
    >
      {tags.map((tag) => (
        <Option key={tag} value={tag}>
          {tag}
        </Option>
      ))}
    </StyledSelect>
  );
};

export default TagsField;
