export const getFilteredTags = (tags: string[]) => {
  const listOfHiddenTags = [
    'SCHEMAS_WITH_TAG',
    'TABLES_WITH_TAG',
    'ALL_TABLES',
  ];

  return tags?.filter(
    (tag) => !listOfHiddenTags.some((hiddenTag) => tag.includes(hiddenTag)),
  );
};
