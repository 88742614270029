import { RematchDispatch } from '@rematch/core';
import { RootModel } from '../../store';
import DataProductService from '../../../services/DataProductService';

export const subscriptionEffects = (dispatch: RematchDispatch<RootModel>) => ({
  async fetchSubscriptions() {
    try {
      dispatch.subscriptions.fetchSubscriptionRequest();
      const subscribedDoamins =
        await DataProductService.getDataDomainSubscriptions();
      dispatch.subscriptions.fetchSubscriptionSuccess(subscribedDoamins);
    } catch (error: unknown) {
      dispatch.subscriptions.fetchSubscriptionFailure((error as Error).toString());
    }
  },

  async subcribeDataDomain(dataDomain: string) {
    try {
      dispatch.subscriptions.SubscribeDataDomainRequest();
      const isSaved = await DataProductService.saveSubscriptions(
        dataDomain,
        true,
      );
      if (isSaved) {
        dispatch.subscriptions.SubscribeDataDomainSuccess(dataDomain);
      }
    } catch (error: unknown) {
      dispatch.subscriptions.SubscribeDataDomainFailure((error as Error).toString());
    }
  },
  async unSubcribeDataDomain(dataDomain: string) {
    try {
      dispatch.subscriptions.UnsubscribeDataDomainRequest();
      const isSaved = await DataProductService.saveSubscriptions(
        dataDomain,
        false,
      );
      if (isSaved) {
        dispatch.subscriptions.UnsubscribeDataDomainSuccess(dataDomain);
      }
    } catch (error: unknown) {
      dispatch.subscriptions.UnsubscribeDataDomainFailure((error as Error).toString());
    }
  },
});
