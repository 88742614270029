import { useState } from 'react';
import FiltersComponent, {
  SelectedView,
} from '../../../../sections/FiltersComponent/FiltersComponent';
import ListComponent from '../../../../sections/ListComponent/ListComponent';
import MainBanner from '../MainBanner/MainBanner';
import {
  BodyContainer,
  CatalogueContainer,
  CenteredContainer,
  MainBannerContainer,
  InnerBodyContainer,
} from './MainSectionStyles';

const MainSection = () => {
  const [selectedView, setSelectedView] = useState<SelectedView>(`Table`);
  const handleChange = (selectedValues: SelectedView) => {
    setSelectedView(selectedValues);
  };

  return (
    <CenteredContainer>
      <MainBannerContainer>
        <MainBanner />
      </MainBannerContainer>
      <BodyContainer>
        <InnerBodyContainer>
          <FiltersComponent
            selectedView={selectedView}
            handleChange={handleChange}
          />
          <CatalogueContainer>
            <ListComponent selectedView={selectedView} />
          </CatalogueContainer>
        </InnerBodyContainer>
      </BodyContainer>
    </CenteredContainer>
  );
};

export default MainSection;
