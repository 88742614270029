import { Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledText = styled(Text)`
  white-space: pre-line;
  padding-bottom: 30px;
`;

export const ModalContent = styled.div`
  display: flex;
`;

export const StyledImage = styled.img`
  height: auto;
  max-height: 100%;
`;

export const Content = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  align-self: center;
  min-width: 25rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 20px;
  }
`;
