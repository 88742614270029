import React, { useEffect, useState } from 'react';
import {
  DomainSubscription,
  MySubscriptionTable,
} from '../MySubscriptionPageComponent/MySubscriptionPageComponent.styles';
import { Toggle } from '@sede-x/shell-ds-react-framework';
import { useFetchDataDomain } from '../../../../hooks/useFetchDataDomain';
import useSubscription from '../../../../hooks/useSubscription';
import { DataDomain } from '../../../../models/dataDomains';

const MySubscriptionPageComponent = () => {
  const { dataDomains, loading } = useFetchDataDomain();
  const { subscribedDomains, subscribe, unsubscribe } = useSubscription();
  const [subscriptionState, setSubscriptionState] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const initialSubscriptionState = dataDomains?.reduce((acc, dataDomain) => {
      acc[dataDomain.name] = subscribedDomains.includes(dataDomain.name);
      return acc;
    }, {} as { [key: string]: boolean });
    setSubscriptionState(initialSubscriptionState || {});
  }, [dataDomains, subscribedDomains]);

  const handleToggleChange = (dataDomainName: string, checked: boolean) => {
    setSubscriptionState((prevState) => ({
      ...prevState,
      [dataDomainName]: checked,
    }));
    if (checked) {
      subscribe(dataDomainName);
    } else {
      unsubscribe(dataDomainName);
    }
  };

  return (
    <MySubscriptionTable>
      <DomainSubscription level={2} bold>
        Data Domain
      </DomainSubscription>
      <DomainSubscription level={2} bold>
        Subscribe
      </DomainSubscription>
      {loading && <p>Loading Data Domains...</p>}
      {dataDomains?.map((dataDomain: DataDomain) => (
        <React.Fragment key={dataDomain.name}>
          <DomainSubscription level={5}>{dataDomain.name}</DomainSubscription>
          <DomainSubscription level={5}>
            <Toggle
              checked={subscriptionState[dataDomain.name] || false}
              onChange={(checked) =>
                handleToggleChange(dataDomain.name, checked)
              }
              size="medium"
            />
          </DomainSubscription>
        </React.Fragment>
      ))}
    </MySubscriptionTable>
  );
};

export default MySubscriptionPageComponent;
