import { DataProductType } from '../../../../../../../../state/models/dataProductsModel/types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonVariant,
  Checkbox,
  FormField,
  Heading,
  Modal,
  TextArea,
} from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import ReportIssueErrorModal from './ReportIssueErrorModal';
import ReportIssueSuccessModal from './ReportIssueSuccessModal';
import {
  Actions,
  CheckboxList,
  Content,
  ModalContent,
  StyledImage,
  StyledText,
} from './ReportIssueModal.styles';
import DataProductService from '../../../../../../../../services/DataProductService';
import { AxiosError } from 'axios';
import image from '../../../../../../../../assets/images/report-issue-banner.jpg';
interface Props {
  dataProduct: DataProductType;
  onCancel: () => void;
}

const maxChar = 500;

const ReportIssueModal = ({ dataProduct, onCancel }: Props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(t('error_generic-required'));

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const validate = (text: string) => {
    if (!text) {
      setMessage(t('error_generic-required'));
      return;
    }
    if (text.length > maxChar) {
      setMessage(t('error_generic-char_limit'));
      return;
    }
    setMessage('');
  };

  const onSend = async () => {
    setIsLoading(true);
    try {
      await DataProductService.reportIssue(dataProduct.name, {
        businessName: dataProduct.businessName,
        dpOwnerEmail: dataProduct.owner.email,
        issueDescription: description,
        link: window.location.href,
        issueType: selectedLabels,
      });
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setIsError(true);
      }
    }
  };

  const actions = [
    {
      label: t('modal_generic-cancel'),
      action: () => {
        onCancel();
      },
      props: {
        variant: 'outlined' as ButtonVariant,
        disabled: isLoading,
      },
      key: 'cancel',
    },
    {
      label: t('modal_generic-send'),
      action: onSend,
      props: { disabled: isLoading || !!message },
      key: 'send',
    },
  ];

  const issueList = [
    { key: 'data_inconsistency', label: 'Data inconsistency or inaccuracy' },
    { key: 'auth_issues', label: 'Authentication/Authorization issues' },
    { key: 'error_messages', label: 'Error messages or exceptions' },
    { key: 'other_issues', label: 'Other issues' },
  ];

  if (isSuccess) {
    return <ReportIssueSuccessModal onClose={onCancel} />;
  }
  if (isError) {
    return <ReportIssueErrorModal onClose={onCancel} />;
  }

  const handleCheckboxChange = (issueLabel: string, checked: boolean) => {
    if (checked) {
      setSelectedLabels((prev) => [...prev, issueLabel]);
    } else {
      setSelectedLabels((prev) => prev.filter((label) => label !== issueLabel));
    }
  };

  const handleCheckboxChangeWrapper =
    (issueLabel: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      handleCheckboxChange(issueLabel, e.target.checked);
    };

  return (
    <Modal
      open
      onClose={onCancel}
      title={t('report_issue_modal-header')}
      actions={actions}
      bodyPadding={false}
      showHeader={false}
      showFooter={false}
      width="65vw"
      closable={false}
      loading={isLoading}
    >
      <ModalContent>
        <StyledImage src={image} alt="Report Issue Modal form" />
        <Content>
          <Heading level={2} bold gutter>
            {t('report_issue_modal-header')}
          </Heading>
          <StyledText>{t('report_issue_modal-body')}</StyledText>

          <CheckboxList>
            {issueList.map((issue) => (
              <FormField key={issue.key} mandatory={false}>
                <Checkbox
                  onChange={(e) => {
                    handleCheckboxChangeWrapper(issue.label)(e);
                  }}
                  label={issue.label}
                />
              </FormField>
            ))}
          </CheckboxList>
          <FormField
            label={t('report_issue_modal-body_description_label')}
            mandatory
            bottomLeftHelper={{
              content: message,
              sentiment: 'negative',
            }}
            bottomRightHelper={{
              content: `${description.length}/${maxChar}`,
              sentiment: 'secondary',
            }}
          >
            <TextArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                validate(e.target.value);
              }}
              placeholder={t('report_issue_modal-body_description_placeholder')}
              rows={3}
              disabled={isLoading}
              invalid={!!message}
            />
          </FormField>
          <Actions>
            {actions.map((action) => (
              <Button
                key={action.key}
                onClick={action.action}
                disabled={action.props.disabled}
                className={`btn ${action.props.variant}`}
                variant={action.props.variant}
              >
                {action.label}
              </Button>
            ))}
          </Actions>
        </Content>
      </ModalContent>
    </Modal>
  );
};

export default ReportIssueModal;
