import { URL_FORMAT } from '../../../../../utils/regexpConst';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';

import CustomFileStatus from '../../../../../components/FormFields/CustomFileStatus/CustomFileStatus';
import Dropzone from '../../../../../components/Dropzone/Dropzone';

const ProductDetailsFieldsResource = (t: TypeTranslation) => [
  {
    name: 'showRequestAccessButton',
    type: 'CHECKBOX',
    text: 'Show Request Access Button',
  },
  {
    label: t('admin_create-product-selector-resource_sample_reference_file'),
    name: 'schemaFiles',
    description: t(
      'admin_create-product-selector-resource_sample_reference_file_type',
    ),
    type: 'CUSTOM',
    component: ({
      onChange,
      value,
    }: {
      onChange: (file: File | undefined) => void;
      value: File;
    }) => (
      <Dropzone
        onChange={(file) => {
          onChange(file);
        }}
        value={value}
        allowedFiles={{
          'application/json': ['.json'],
          'application/csv': ['.csv'],
        }}
      />
    ),
  },
  {
    name: 'schemaFileDelete',
    type: 'CUSTOM',
    component: CustomFileStatus,
  },

  {
    name: 'productDetailsURL',
    subtitle: t('admin_create-product_details_step-heading_url_description'),
    title: t('admin_create-product_details_step-heading_url_title'),
    type: 'HEADING',
  },
  [
    {
      label: t('admin_create-product_details_step-url_title'),
      name: 'url',
      description: t('admin_create-product_details_step-url_description'),
      placeholder: t('admin_create-product_details_step-url_placeholder'),
      required: true,
      type: 'TEXT_INPUT',
      validator: new RegExp(URL_FORMAT),
    },
  ],
];

export default ProductDetailsFieldsResource;
