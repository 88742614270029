import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const AltItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

export const AltItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

export const AltItemAvatar = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.background.surface};
  border: 1px solid ${({ theme }) => theme.background.raised};
  color: ${({ theme }) => theme.background.inverted};
`;

export const AltBusinessName = styled.div`
  flex: 0 0 70%;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 20px;
`;

export const AltTypeLikes = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  & > :last-child {
    padding-right: 40px;
  }
`;

export const ItemAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.background.surface};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.background.raised};
  color: ${({ theme }) => theme.background.inverted};
`;

export const TagContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  color: ${({ theme }) => theme.background.surface};
`;

export const ButtonContainer = styled.div`
  margin: 14px 0;
`;

export const TruncateText = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
