import { useTranslation } from 'react-i18next';
import SuccessIcon from './SuccessIcon';
import GenericResponsePage from '../../GenericResponsePage/GenericResponsePage';
import { useLocation } from 'react-router-dom';
import { productTypes } from '../../../../../utils/dataProductTypes';

const ConfirmationPageContent = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const isGlass = state.createdType === productTypes.Glass;

  return isGlass ? (
    <GenericResponsePage
      isGlassProduct
      icon={<SuccessIcon />}
      title={t('admin_success_create_glass-title')}
      description={t('admin_success_create_glass-description')}
      email={t('admin_response_glass-email')}
      textEmail={t('admin_response_glass-email_text')}
      textCreateButton={t('admin_success_create_glass-button_create')}
      textManageButton={t('admin_success_create_glass-button_manage')}
    />
  ) : (
    <GenericResponsePage
      icon={<SuccessIcon />}
      title={t('admin_success_create-title', {
        dataProductName: state?.dataProductName,
      })}
      description={t('admin_success_create-description')}
      email={t('admin_success_create-email')}
      textEmail={t('admin_success_create-email_text')}
      textCreateButton={t('admin_success_create-button_create')}
      textManageButton={t('admin_success_create-button_manage')}
    />
  );
};

export default ConfirmationPageContent;
