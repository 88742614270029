import { useState } from 'react';
import { Container, StyledText } from './Footer.styles';
import { useTranslation } from 'react-i18next';
import { Modal, Spacings } from '@sede-x/shell-ds-react-framework';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

const Footer = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Container data-testid="page-footer">
      <a href={t('footer-publish_data_link')} target="_blank" rel="noreferrer">
        {t('footer-publish_data')}
      </a>
      <a href={t('footer-use_data_link')} target="_blank" rel="noreferrer">
        {t('footer-use_data')}
      </a>
      <a
        href={t('footer-data_agreement_link')}
        target="_blank"
        rel="noreferrer"
      >
        {t('footer-data_agreement')}
      </a>
      <StyledText size="small" onClick={() => setFeedbackOpen(true)}>
        {t('footer-feedback')}
      </StyledText>

      <Modal
        open={feedbackOpen}
        onClose={() => setFeedbackOpen(false)}
        bodyPadding={false}
        padding={Spacings.Tight}
        showHeader={false}
        width="60vw"
        closable={false}
      >
        <FeedbackForm onClose={() => setFeedbackOpen(false)} />
      </Modal>
    </Container>
  );
};

export default Footer;
