import { Heading, Icons } from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import {
  ButtonsContainer,
  CategoryText,
  DetailButton,
  LeftContainer,
  LinkSpaced,
  StyledAnchor,
  StyledButtonsContainer,
  StyledCategory,
  StyledPublicLabel,
} from './LeftHeader.styles';
import { updateTimeAndVersion } from '../../../../../../utils/dateHelper';
import TheButton from './TheButton/TheButton';
import ReportIssueButton from './ReportIssueButton/ReportIssueButton';
import VersionSelectorButton from './VersionSelectorButton/VersionSelectorButton';
import { LeftHeaderProps } from './LeftHeader';
import LikeButton from './LikeButton/LikeButton';
import ShareBar from './ShareBar/ShareBar';
import { productTypes } from '../../../../../../utils/dataProductTypes';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../../state/store';
import { useSelector } from 'react-redux';

import EditButton from './EditButton/EditButton';
import DownloadButton from './DownloadButton/DownloadButton';
import RequestAccessModal from './TheButton/variants/components/RequestAccessModal';

const TwoLinesInfo = ({
  type,
  updateTime,
}: {
  type: string;
  updateTime: number;
}) => (
  <>
    <span>{type}</span>
    <span>{updateTimeAndVersion(updateTime)}</span>
  </>
);
const OneLineInfo = ({
  type,
  updateTime,
}: {
  type: string;
  updateTime: number;
}) => <span>{`${type} ${updateTimeAndVersion(updateTime)}`}</span>;

const OpenButton = ({ url }: { url?: string }) => (
  <StyledAnchor href={url} target="_blank">
    <DetailButton icon={<Icons.Open fill="#fff" />} iconPosition="right">
      Open
    </DetailButton>
  </StyledAnchor>
);

const LeftHeaderLayout: React.FC<LeftHeaderProps> = ({
  dataProduct,
  onChangeVersion,
}) => {
  const { t } = useTranslation();
  const { email } = useSelector((state: RootState) => state.user.user);
  const [open, setOpen] = useState(false);

  const isOwner = React.useMemo(() => {
    if (dataProduct && email) {
      const { owner, expert } = dataProduct;
      const lowerCaseEmail = email.toLowerCase();
      return (
        owner?.email.toLowerCase() === lowerCaseEmail ||
        expert?.email.toLowerCase() === lowerCaseEmail
      );
    }
    return false;
  }, [dataProduct, email]);

  const categoryInfo = {
    [productTypes.API]: {
      icon: <Icons.DesignSystem height={20} width={20} />,
      versionTexts: (
        <TwoLinesInfo
          type={dataProduct.type}
          updateTime={dataProduct?.updateTime}
        />
      ),
      consumptionButton: <TheButton dataProduct={dataProduct} />,
    },
    [productTypes.Event]: {
      icon: <Icons.Calendar height={20} width={20} />,
      versionTexts: (
        <TwoLinesInfo
          type={dataProduct.type}
          updateTime={dataProduct?.updateTime}
        />
      ),
      consumptionButton: <TheButton dataProduct={dataProduct} />,
    },
    [productTypes.Streaming]: {
      icon: <Icons.LineChartSolid height={20} width={20} />,
      versionTexts: (
        <TwoLinesInfo
          type={dataProduct.type}
          updateTime={dataProduct?.updateTime}
        />
      ),
      consumptionButton: <TheButton dataProduct={dataProduct} />,
    },
    [productTypes.Resource]: {
      icon: <Icons.FileText height={20} width={20} />,
      versionTexts: (
        <OneLineInfo
          type={dataProduct.type}
          updateTime={dataProduct?.updateTime}
        />
      ),
      consumptionButton: <OpenButton url={dataProduct.url} />,
    },
    [productTypes.Batch]: {
      icon: <Icons.RowsSolid height={20} width={20} />,
      versionTexts: (
        <OneLineInfo
          type={dataProduct.type}
          updateTime={dataProduct?.updateTime}
        />
      ),
      consumptionButton: <OpenButton url={dataProduct.url} />,
    },
  };

  const currentCategoryInfo = categoryInfo[dataProduct.type || 'API'];

  const showDownloadButton =
    dataProduct.containsFile && dataProduct.type === productTypes.Resource;

  const checkHighestValue = dataProduct?.versions?.reduce(
    (prev, current) => {
      return prev && parseFloat(prev.number) > parseFloat(current.number)
        ? prev
        : current;
    },
    { number: '0.0.0' },
  );
  const highestVersion = dataProduct.version === checkHighestValue?.number;
  const showRequestAccessButton =
    dataProduct.type === productTypes.Resource &&
    (dataProduct.showRequestAccessButton?.toString() ?? 'false') === 'true';
  return (
    <LeftContainer>
      {dataProduct.accessType === 'public' && (
        <StyledPublicLabel size="medium" color="light">
          Public Access
        </StyledPublicLabel>
      )}
      <Heading level={1} bold>
        {dataProduct?.businessName}
      </Heading>
      <StyledCategory>
        {currentCategoryInfo.icon}
        <CategoryText>{currentCategoryInfo.versionTexts}</CategoryText>
        {dataProduct.version && (
          <VersionSelectorButton
            versions={dataProduct.versions}
            currentVersion={dataProduct.version}
            onChangeVersion={onChangeVersion}
          />
        )}
      </StyledCategory>
      <ButtonsContainer>
        {showRequestAccessButton ? (
          <>
            <DetailButton
              icon={<Icons.Lock />}
              iconPosition="right"
              onClick={() => setOpen(true)}
            >
              Request access
            </DetailButton>
            {open && (
              <RequestAccessModal
                dataProduct={dataProduct}
                onCancel={() => setOpen(false)}
              />
            )}
          </>
        ) : (
          currentCategoryInfo.consumptionButton
        )}

        <ReportIssueButton dataProduct={dataProduct} />
        {isOwner && highestVersion && <EditButton dataProduct={dataProduct} />}
      </ButtonsContainer>
      <StyledButtonsContainer>
        <LikeButton dataProduct={dataProduct} />
        <ShareBar
          name={dataProduct.name}
          businessName={dataProduct.businessName}
          description={dataProduct.description}
        />
        {showDownloadButton && <DownloadButton name={dataProduct.name} />}
      </StyledButtonsContainer>
      {dataProduct.type === productTypes.Batch && (
        <LinkSpaced
          underlined={false}
          bold
          href={t('details-batch-consumption_url')}
        >
          {t('details-batch-consumption_text')}
        </LinkSpaced>
      )}
    </LeftContainer>
  );
};

export default LeftHeaderLayout;
