import { createModel } from '@rematch/core';
import { DataDomain, DataDomainState } from './dataDomain';
import { RootModel } from '../../store';
import { dataDomainEffects } from './dataDomainEffects';

const initialState: DataDomainState = {
  items: [],
  loading: false,
  error: null,
};

export const dataDomainsModel = createModel<RootModel>()({
  state: initialState,
  effects: dataDomainEffects,
  reducers: {
    fetchDataDomainRequest(state: DataDomainState) {
      return { ...state, loading: true, error: null };
    },
    fetchDataDomainSuccess(state: DataDomainState, payload: DataDomain[]) {
      return { ...state, loading: false, items: payload };
    },
    fetchDataDomainFailure(state: DataDomainState, payload: string) {
      return { ...state, loading: false, error: payload };
    },
  },
});
