import { Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5rem;
  max-width: 1024px;
`;

export const ResultsCount = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  padding: 0 40px;
`;

export const PaginationSizeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SelectBox = styled.div`
  margin-right: 20px;
  width: 5rem;
`;

export const DefaultResultsCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
`;

export const DefaultResultsText = styled(Text)`
  color: ${({ theme }) => theme.background.raised};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-weight: bold;
  width: 100%;
`;

export const HeaderIconPlaceholder = styled.div`
  flex: 0 0 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const HeaderBusinessName = styled.div`
  flex: 0 0 70%;
  text-align: left;
`;

export const HeaderType = styled.div`
  flex: 1;
  text-align: left;
`;

export const HeaderLikes = styled.div`
  flex: 1;
  text-align: right;
  padding-right: 20px;
`;
