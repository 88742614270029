import { Alert, Heading, Sizes } from '@sede-x/shell-ds-react-framework';
import { useEffect, useState } from 'react';
import StepperHeader from '../../../components/StepperHeader/StepperHeader';
import ConfigurationSection from '../../ConfigurationSection/ConfigurationSection';
import GovernanceSection from '../../GovernanceSection/GovernanceSection';
import GovernanceSectionGlass from '../../GovernanceSection/GovernanceSectionGlass';
import ProductDetailsSection from '../../ProductDetailsSection/ProductDetailsSection';
import ProductDetailsSectionGlass from '../../ProductDetailsSection/ProductDetailsSectionGlass';
import DeploymentSection from '../../DeploymentSection/DeploymentSection';
import { Container } from '../common/AdminFormCreateSection.styles';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../../routes/routes';
import {
  DataProductVariant,
  ProductVariant,
  productTypes,
} from '../../../../../../utils/dataProductTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import LoadingFullPageComponent from '../../../../../../components/LoadingFullPageComponent/LoadingFullPageComponent';
import { useTranslation } from 'react-i18next';
import PropertiesSection from '../../PropertiesSection/PropertiesSection';
import LinkWithIcon from '../../../../../../components/LinkWithIcon/LinkWithIcon';

type Props = {
  type: ProductVariant;
};

const AdminFormCreateSection = ({ type }: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { email } = useSelector((state: RootState) => state.user.user);
  const [currentValues, setCurrentValues] = useState<{
    [key: string]: unknown;
  }>({ owner: email, customMetadata: '[]', type });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentError, isLoading } = useSelector((state: RootState) =>
    type === productTypes.Glass ? state.adminGlass : state.admin,
  );

  useEffect(
    () => () => {
      dispatch.admin.cleanError();
      dispatch.adminGlass.cleanError();
      if (type === productTypes.Glass) {
        const { customMetadata, ...rest } = currentValues;

        setCurrentValues(rest);
      }
    },
    [],
  );

  const navigate = useNavigate();

  const stepUp = () => setCurrentStep(currentStep + 1);
  const stepBack = () => setCurrentStep(currentStep - 1);

  const saveAndGoAhead = (values: { [key: string]: unknown }) => {
    setCurrentValues({ ...currentValues, ...values });
    stepUp();
  };
  const submit = async (values: { [key: string]: unknown }) => {
    setCurrentValues({ ...currentValues, ...values });
    const submitValues = {
      ...currentValues,
      ...values,
    };

    let response;

    if (type === productTypes.Glass) {
      dispatch.adminGlass.cleanError();
      response = await dispatch.adminGlass.createGlassProduct(submitValues);
    } else {
      dispatch.admin.cleanError();
      response = await dispatch.admin.createDataProduct(submitValues);
    }

    if (response < 300) {
      navigate(routes.success, {
        state: {
          createdType: type,
        },
      });
    }
    if (response >= 500) {
      navigate(routes.error, {
        state: {
          createdType: type,
        },
      });
    }
  };

  const firstElementProps = {
    onSubmit: saveAndGoAhead,
    onCancel: () => {
      type === productTypes.Glass
        ? navigate(`${routes.admin}${routes.adminGlassHubRelative}`)
        : navigate(routes.create);
    },
  };

  const elementProps = {
    onSubmit: saveAndGoAhead,
    onCancel: stepBack,
    defaultValues: currentValues,
    type: type as DataProductVariant,
  };
  const lastElementProps = { onSubmit: submit, onCancel: stepBack };

  const variant = {
    API: [
      <ProductDetailsSection
        {...elementProps}
        {...firstElementProps}
        key={'ProductDetailsSection'}
      />,
      <ConfigurationSection {...elementProps} key={'ConfigurationSection'} />,
      <GovernanceSection {...elementProps} key={'GovernanceSection'} />,
      <DeploymentSection
        {...elementProps}
        {...lastElementProps}
        defaultValues={currentValues}
      />,
    ],
    Event: [
      <ProductDetailsSection
        {...elementProps}
        {...firstElementProps}
        key={'ProductDetailsSection'}
      />,
      <ConfigurationSection {...elementProps} key={'ConfigurationSection'} />,
      <GovernanceSection
        {...elementProps}
        {...lastElementProps}
        key={'GovernanceSection'}
      />,
    ],
    Streaming: [
      <ProductDetailsSection
        {...elementProps}
        {...firstElementProps}
        key={'ProductDetailsSection'}
      />,
      <ConfigurationSection {...elementProps} key={'ConfigurationSection'} />,
      <GovernanceSection
        {...elementProps}
        {...lastElementProps}
        key={'GovernanceSection'}
      />,
    ],
    Resource: [
      <ProductDetailsSection
        {...elementProps}
        {...firstElementProps}
        key={'ProductDetailsSection'}
      />,
      <GovernanceSection
        {...elementProps}
        {...lastElementProps}
        key={'GovernanceSection'}
      />,
    ],
    Batch: [
      <ProductDetailsSection
        {...elementProps}
        {...firstElementProps}
        key={'ProductDetailsSection'}
      />,
      <PropertiesSection {...elementProps} key={'PropertiesSection'} />,
      <GovernanceSection
        {...elementProps}
        {...lastElementProps}
        key={'GovernanceSection'}
      />,
    ],
    Glass: [
      <ProductDetailsSectionGlass
        {...elementProps}
        {...firstElementProps}
        key={'ProductDetailsSection'}
      />,
      <GovernanceSectionGlass
        {...elementProps}
        {...lastElementProps}
        key={'GovernanceSection'}
      />,
    ],
  };

  const contents = variant[type];

  return (
    <Container>
      <Heading bold>
        {type !== productTypes.Glass
          ? t('admin_create-product_main_title', { type: productTypes[type] })
          : t('admin_create-product_main_title_glass')}
      </Heading>
      <StepperHeader
        currentStep={currentStep}
        onChangeStep={(newStep: number) => setCurrentStep(newStep)}
        type={type}
      />
      {currentError && (
        <Alert sentiment="negative" size={Sizes.Large}>
          {currentError}
        </Alert>
      )}
      {contents[currentStep]}
      {type !== productTypes.Glass && (
        <LinkWithIcon href={t('admin_create-product_main_link')}>
          {t('admin_create-product_main_link_text')}
        </LinkWithIcon>
      )}
      {isLoading && <LoadingFullPageComponent />}
    </Container>
  );
};

export default AdminFormCreateSection;
