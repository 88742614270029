import { init, RematchDispatch, RematchRootState, Models } from '@rematch/core';
import dataProductsModel from './models/dataProductsModel';
import insightsModel from './models/insightsModel';
import userModel from './models/userModel';
import adminModel from './models/adminModel';
import adminModelGlass from './models/adminModelGlass';
import { dataDomainsModel } from './models/dataDomains/dataDomainReducers';
import { subscriptionsModel } from './models/subscriptionModel/subscriptionReducer';

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

export interface RootModel extends Models<RootModel> {
  dataProducts: typeof dataProductsModel;
  insights: typeof insightsModel;
  user: typeof userModel;
  admin: typeof adminModel;
  adminGlass: typeof adminModelGlass;
  dataDomains: typeof dataDomainsModel;
  subscriptions: typeof subscriptionsModel;
}

export const models: RootModel = {
  dataProducts: dataProductsModel,
  insights: insightsModel,
  user: userModel,
  admin: adminModel,
  adminGlass: adminModelGlass,
  dataDomains: dataDomainsModel,
  subscriptions: subscriptionsModel,
};

const store = init({
  models,
});

export default store;
