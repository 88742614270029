import React from 'react';
import {
  LoadingBusinessName,
  LoadingIconPlaceholder,
  LoadingLikes,
  LoadingRowCardContainer,
  LoadingType,
} from './LoadingRowCard.styles';

interface LoadingRowCardProps {
  numRows?: number;
}

const LoadingRowCard: React.FC<LoadingRowCardProps> = ({ numRows = 10 }) => {
  return (
    <div data-testid="Loading-list">
      {Array.from({ length: numRows }).map((_, index) => (
        <LoadingRowCardContainer
          key={`loading-row-` + index}
          data-testid={`loading-row`}
        >
          <LoadingIconPlaceholder data-testid={`loading-row-icon`} />
          <LoadingBusinessName />
          <LoadingType />
          <LoadingLikes />
        </LoadingRowCardContainer>
      ))}
    </div>
  );
};

export default LoadingRowCard;
