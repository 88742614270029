import styled from 'styled-components';

export const ListSectionContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const ListContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
`;

export const FiltersContainer = styled.div`
  width: 264px;
`;
