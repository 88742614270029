import styled from 'styled-components';

export const ContentContainer = styled.div`
  flex: 1;
  height: calc(100% - 72px);
  padding-bottom: 70px;
`;

export const RightContainer = styled.div`
  display: flex;
`;
