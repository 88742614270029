import { Container } from './AdminListSection.styles';
import { TableSystem } from '@sede-x/glass-design-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { useEffect, useState } from 'react';
import TableActions from './helpers/TableActions/TableActions';
import { useTranslation, Trans } from 'react-i18next';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import FieldWithLinkIcon from './helpers/FieldWithIcon/FieldWithIcon';
import ConfirmationDeleteModal from './helpers/TableActions/ConfirmationDeleteModal/ConfirmationDeleteModal';
import ErrorModal from './helpers/TableActions/ErrorModal/ErrorModal';
import SuccessModal from './helpers/TableActions/SucessModal/SucessModal';
import {
  GlassProductAdminListType,
  HierarchyItem,
} from '../../../../../state/models/adminModelGlass/types';
import GlassHubEmptyState from './components/AdminListEmptyState/AdminListGlassHubEmptyState';
import routes from '../../../../../routes/routes';
import { useNavigate } from 'react-router-dom';
import { Icons, Modal } from '@sede-x/shell-ds-react-framework';
import { Sizes, Variants } from '@sede-x/shell-ds-react-base';
import { configureEmailForFolderChange } from '../../../../../utils/emailHelper';
import { getGlasshubEnv } from '../../../../../components/Layout/Layout';

const columns = (t: TypeTranslation) => [
  {
    dataIndex: 'icon',
    key: 'icon',
    sortable: false,
    title: '',
    width: 1,
  },
  {
    dataIndex: 'link',
    key: 'link',
    title: '',
    width: 1,
  },
  {
    dataIndex: 'name',
    key: 'name',
    sortable: true,
    title: t('admin_list_glass-column_title-name'),
  },
  {
    dataIndex: 'folder',
    key: 'folder',
    sortable: true,
    title: t('admin_list_glass-column_title-folder'),
  },
  {
    dataIndex: 'update',
    key: 'update',
    sortable: true,
    title: t('admin_list_glass-column_title-update'),
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    title: t('admin_list_glass-column_title-actions'),
    width: 1,
  },
];

const pageSize = 10;

const AdminListSectionGlass = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedSuccessfully, setDeletedSuccessfully] = useState(false);
  const [currentGlassProduct, setCurrentGlassProduct] = useState<
    GlassProductAdminListType | undefined
  >(undefined);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = (gP: GlassProductAdminListType) => {
    setCurrentGlassProduct(gP);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleEmail = async (
    gP: GlassProductAdminListType,
    folderName: any,
  ) => {
    const env = getGlasshubEnv();
    window.location.href = configureEmailForFolderChange(
      t,
      gP.title,
      gP.id,
      gP.parent,
      env,
      folderName,
      gP.technicalOwner,
      gP.owner,
    );
  };

  const { ownGlassProducts, totalGlassItems, isLoading, hasError } =
    useSelector((state: RootState) => {
      return state.adminGlass;
    });

  useEffect(() => {
    dispatch.adminGlass.getOwnGlassProducts({ page });
  }, [page]);

  const data = ownGlassProducts.map((gP) => {
    const ProductIcon = Icons[gP.icon as keyof typeof Icons] ?? Icons.Lightbulb;
    const lastUpdate = gP.modified_date ?? gP.created_date;

    const convertToFolderStructure = (items: HierarchyItem[]): string => {
      if (items.length === 0) {
        return 'None';
      }
      const titles = items.slice(0, -1).map((item) => item.title);
      return titles.join(' / ');
    };

    const folderName: string | null = convertToFolderStructure(
      gP.hierarchy ?? [],
    );

    return {
      icon: <ProductIcon height={20} width={20} />,
      link: (
        <FieldWithLinkIcon
          text={''}
          href={`${routes.details}/${gP.link}`}
          icon={<Icons.Open fill="#fff" width={20} height={20} />}
        />
      ),
      name: gP.title,
      folder: (
        <>
          <FieldWithLinkIcon
            text={gP.parent === null ? 'None' : folderName ?? ''}
            href={''}
            external={false}
            icon={
              <Icons.SwapHorizontalSquare
                data-testid="folder-change-icon"
                width={20}
                height={20}
                fill="#fff"
                onClick={() => handleOpenModal(gP)}
              />
            }
          />
          <Modal
            data-testid="folder-change-modal"
            open={isOpen}
            onClose={handleCloseModal}
            size={Sizes.Large}
            title={t('modal_folder_title', {
              glass_product_title: `"${currentGlassProduct?.title}"`,
            })}
            actions={[
              {
                label: 'Cancel',
                action: () => {
                  handleCloseModal();
                },
                props: { variant: Variants.Outlined },
              },
              {
                label: 'OK',
                action: () => {
                  handleEmail(
                    currentGlassProduct as GlassProductAdminListType,
                    currentGlassProduct?.hierarchy?.slice(-2, -1)[0]?.title ??
                      'None',
                  );
                },
              },
            ]}
          >
            <Trans
              t={t}
              i18nKey="modal_folder_name_change-description"
              components={{ p: <p />, br: <br /> }}
            />
          </Modal>
        </>
      ),
      update: lastUpdate
        ? new Date(lastUpdate).toLocaleDateString('en-GB')
        : '',
      actions: (
        <TableActions
          onDelete={() => {
            setCurrentGlassProduct(gP);
            setIsDeleting(true);
          }}
          handleEdit={() => navigate(`${routes.editGlassHub}/${gP.id}`)}
        />
      ),
    };
  });

  const handleDelete = async () => {
    await dispatch.adminGlass.deleteGlassProduct({
      id: currentGlassProduct?.id,
    });
    setDeletedSuccessfully(true);
  };

  const confirmModalOpen = isDeleting && !hasError && !deletedSuccessfully;
  const errorModalOpen = isDeleting && hasError;
  const successModalOpen = isDeleting && !hasError && deletedSuccessfully;

  const confirmModal = confirmModalOpen && (
    <ConfirmationDeleteModal
      onConfirm={async () => {
        await handleDelete();
      }}
      onCancel={() => setIsDeleting(false)}
      productTitle={currentGlassProduct?.title ?? ''}
      isGlass
    />
  );

  const errorModal = errorModalOpen && (
    <ErrorModal
      title={currentGlassProduct?.title ?? ''}
      onTryAgain={() => {
        setDeletedSuccessfully(false);
        setIsDeleting(true);
        dispatch.adminGlass.cleanError();
      }}
      onCancel={() => {
        setIsDeleting(false);
        dispatch.adminGlass.cleanError();
        setCurrentGlassProduct(undefined);
      }}
      isGlass
    />
  );

  const successModal = successModalOpen && (
    <SuccessModal
      onConfirm={() => {
        setDeletedSuccessfully(false);
        setIsDeleting(false);
        setCurrentGlassProduct(undefined);
        dispatch.adminGlass.getOwnGlassProducts({ page });
      }}
      productName={currentGlassProduct?.title}
      isGlass
    />
  );

  const hasTable: boolean = isLoading || ownGlassProducts.length > 0;

  return (
    <Container>
      {hasTable ? (
        <TableSystem
          columns={columns(t)}
          data={data}
          hasHover={false}
          onPageChange={setPage}
          totalRows={totalGlassItems}
          loading={isLoading}
          maxRowsPerPage={pageSize}
          page={page}
          pagination
        />
      ) : (
        <GlassHubEmptyState />
      )}
      {confirmModal}
      {successModal}
      {errorModal}
    </Container>
  );
};

export default AdminListSectionGlass;
