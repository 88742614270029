import { createModel } from '@rematch/core';
import { SubscriptionState } from './subscription';
import { RootModel } from '../../store';
import { subscriptionEffects } from './subscriptionEffects';

const initialState: SubscriptionState = {
  items: [],
};

export const subscriptionsModel = createModel<RootModel>()({
  state: initialState,
  effects: subscriptionEffects,
  reducers: {
    fetchSubscriptionRequest(state: SubscriptionState) {
      return { ...state, loading: true, error: null };
    },
    fetchSubscriptionSuccess(
      state: SubscriptionState,
      subscribedDataDomains: string[],
    ) {
      return { ...state, loading: false, items: subscribedDataDomains };
    },
    fetchSubscriptionFailure(state: SubscriptionState, error: string) {
      return { ...state, loading: false, error };
    },
    SubscribeDataDomainRequest(state: SubscriptionState) {
      return { ...state, loading: true, error: null };
    },
    SubscribeDataDomainSuccess(state: SubscriptionState, dataDomain: string) {
      return { ...state, loading: false, items: [...state.items, dataDomain] };
    },
    SubscribeDataDomainFailure(state: SubscriptionState, error: string) {
      return { ...state, loading: false, error };
    },
    UnsubscribeDataDomainRequest(state: SubscriptionState) {
      return { ...state, loading: true, error: null };
    },
    UnsubscribeDataDomainSuccess(state: SubscriptionState, dataDomain: string) {
      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item !== dataDomain),
      };
    },
    UnsubscribeDataDomainFailure(state: SubscriptionState, error: string) {
      return { ...state, loading: false, error };
    },
  },
});
