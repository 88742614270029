import { createModel } from '@rematch/core';
import reducers from './reducers';
import effects from './effects';
import { RootModel } from '../../store';
import { DataProductsState, DataProductType } from './types';
const DataProductsModel = createModel<RootModel>()({
  state: {
    dataProducts: [],
    totalItems: 0,
    isLoading: false,
    hasError: false,
    currentDataProduct: {} as DataProductType,
    terms: [],
    tags: [],
    cache: {
      dataProducts: [],
      totalItems: 0,
      rowsPerPage: Number(localStorage.getItem('rowsPerPage')) || 10,
    },
  } as DataProductsState,
  effects,
  reducers,
});
export default DataProductsModel;
