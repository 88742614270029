import { AlertSentiments, Sizes } from '@sede-x/shell-ds-react-framework';
import { AlertStyled } from './BannerField.styles';

interface Props {
  sentiment: AlertSentiments;
  text: string;
}

const BannerField =
  ({ sentiment, text }: Props) =>
  () => {
    return (
      <AlertStyled sentiment={sentiment} label={text} size={Sizes.Small} />
    );
  };

export default BannerField;
