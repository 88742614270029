import { Divider, Icons, Label } from '@sede-x/shell-ds-react-framework';
import { generatePath } from 'react-router-dom';
import { StyledLink } from '../../globalStyles';
import {
  AltBusinessName,
  AltItemAvatar,
  AltItemContainer,
  AltItemWrapper,
  AltTypeLikes,
} from './Item.style';
import TypeBadge from '../TypeBadge/TypeBadge';
import { DataProductVariant } from '../../utils/dataProductTypes';

import { useTranslation } from 'react-i18next';

export interface ItemTypes {
  id: string;
  name: string;
  description: string;
  tags?: string[];
  terms?: string[];
  businessName: string;
  type: DataProductVariant;
  likes?: number;
}

interface Props {
  item: ItemTypes;
}

const typeIcons = {
  API: 'DesignSystem',
  Event: 'Calendar',
  Resource: 'FileText',
  Streaming: 'LineChartSolid',
  Batch: 'RowsSolid',
};

const Item: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const { businessName, type } = item;
  const TypeIcon = Icons[typeIcons[type] as keyof typeof Icons];

  return (
    <StyledLink to={generatePath('/product/:name', { name: item.name })}>
      <AltItemWrapper>
        <AltItemContainer>
          <AltItemAvatar>
            <TypeIcon className="ItemAvatar" data-testid={`icon-${type}`} />
          </AltItemAvatar>
          <AltBusinessName>
            <Label color="normal">{businessName} </Label>
          </AltBusinessName>
          <AltTypeLikes>
            <TypeBadge type={type} iconVisible={false} />
            <Label color="normal">
              {t('admin_list-column_title-user_likes', {
                likes: item.likes?.toLocaleString('en-US') ?? 0,
              })}
            </Label>
          </AltTypeLikes>
        </AltItemContainer>
      </AltItemWrapper>
      <Divider size="small" gutter="none" />
    </StyledLink>
  );
};

export default Item;
