import { Container, StyledLoaderContainer } from './RequestsChart.styles';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { GlassChartTheme } from '@sede-x/glass-design-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import {
  ButtonGroup,
  Heading,
  Loading,
  Sizes,
  Text,
} from '@sede-x/shell-ds-react-framework';
import {
  HeadingContainer,
  LeftHeadingContainer,
  ChartHeadingStyled,
} from '../InsightsPageComponent/InsightsPageComponent.styles';
import {
  getLastUpdateText,
  getMinutesDifference,
} from '../../../../utils/dateHelper';
import { useEffect, useState } from 'react';
import genericChartStyles from '../utils/genericChartStyles';

highchartsAccessibility(Highcharts);

const options = (
  data: { name: string; y: number; type: string }[],
): Highcharts.Options => ({
  colors: ['#54B8C8'],
  ...genericChartStyles,
  xAxis: {
    type: 'category',
  },
  yAxis: {
    title: { text: '' },
  },
  chart: {
    type: 'column',
    height: '400px',
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: 'Requests',
      data,
      type: 'column',
    },
  ],
});

const weekInSeconds = 604800;
const monthInSeconds = 2592000;
const yearInSeconds = 31536000;
const now = Math.round(Date.now() / 1000);
const fromDate = {
  'shell-button-group-Week': now - weekInSeconds,
  'shell-button-group-Month': now - monthInSeconds,
  'shell-button-group-Year': now - yearInSeconds,
};

const RequestsChart = () => {
  const dispatch = useDispatch();
  const [currentTimeRange, setCurrentTimeRange] = useState(
    'shell-button-group-Week',
  );
  const { items, isLoading, lastUpdate } = useSelector(
    (state: RootState) => state.insights.requests,
  );

  useEffect(() => {
    dispatch.insights.getRequests({
      fromDate: fromDate[currentTimeRange as keyof typeof fromDate],
    });
  }, [dispatch.insights, currentTimeRange]);

  const requestsParsed = items.map((rDP) => ({
    name: rDP.businessName,
    y: parseInt(rDP.numberOfRequests, 10),
    type: 'column',
  }));

  const handleChange = (selectedValues: string) => {
    setCurrentTimeRange(selectedValues);
  };

  return (
    <>
      <HeadingContainer>
        <LeftHeadingContainer>
          <ChartHeadingStyled level={3} bold>
            Number of Requests per Data Product
          </ChartHeadingStyled>
          <Heading level={4} color="light">
            {!isLoading
              ? getLastUpdateText(getMinutesDifference(lastUpdate))
              : 'Loading...'}
          </Heading>
        </LeftHeadingContainer>
        <ButtonGroup
          onChange={handleChange}
          size={Sizes.Medium}
          type="radio"
          items={[
            {
              label: 'Week',
            },
            {
              label: 'Month',
            },
            {
              label: 'Year',
            },
          ]}
        />
      </HeadingContainer>
      <Container>
        {!isLoading && requestsParsed.length > 0 && (
          <HighchartsReact
            highcharts={Highcharts}
            options={GlassChartTheme({
              options: options(requestsParsed),
            })}
          />
        )}
        {isLoading && (
          <StyledLoaderContainer>
            <Loading size="large" />
          </StyledLoaderContainer>
        )}
        {!isLoading && requestsParsed.length <= 0 && (
          <StyledLoaderContainer>
            <Text size="large">No Data</Text>
          </StyledLoaderContainer>
        )}
      </Container>
    </>
  );
};

export default RequestsChart;
