import styled, { keyframes } from 'styled-components';

const ShineAnimation = keyframes`
  0% {background-position: top right;}
  100% {background-position: top left;}
`;

// Loading Card Container (Main container)
export const LoadingRowCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.background.surface};
  border-radius: 4px;
  margin-bottom: 8px;
`;

// Placeholder for the icon (left section)
export const LoadingIconPlaceholder = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.background.surface} -webkit-gradient(
      linear,
      left top,
      right top,
      from(${({ theme }) => theme.background.surface}),
      to(${({ theme }) => theme.background.surface}),
      color-stop(0.5, ${({ theme }) => theme.background.inactive})
    ) 0 0 no-repeat;
  animation-name: ${ShineAnimation};
  margin-right: 10px;
`;

// Placeholder for business name (middle section)
export const LoadingBusinessName = styled.div`
  flex: 0 0 70%;
  height: 20px;
  background: ${({ theme }) => theme.background.surface} -webkit-gradient(
      linear,
      left top,
      right top,
      from(${({ theme }) => theme.background.surface}),
      to(${({ theme }) => theme.background.surface}),
      color-stop(0.5, ${({ theme }) => theme.background.inactive})
    ) 0 0 no-repeat;
  animation-name: ${ShineAnimation};
  margin: 5px 10px;
  border-radius: 4px;
`;

// Placeholder for the type (middle section)
export const LoadingType = styled.div`
  flex: 1;
  height: 20px;
  background: ${({ theme }) => theme.background.surface} -webkit-gradient(
      linear,
      left top,
      right top,
      from(${({ theme }) => theme.background.surface}),
      to(${({ theme }) => theme.background.surface}),
      color-stop(0.5, ${({ theme }) => theme.background.inactive})
    ) 0 0 no-repeat;
  margin: 5px 20px;
  border-radius: 4px;
  animation-name: ${ShineAnimation};
`;

// Placeholder for likes (right section)
export const LoadingLikes = styled.div`
  flex: 1;
  height: 20px;
  background: ${({ theme }) => theme.background.surface} -webkit-gradient(
      linear,
      left top,
      right top,
      from(${({ theme }) => theme.background.surface}),
      to(${({ theme }) => theme.background.surface}),
      color-stop(0.5, ${({ theme }) => theme.background.inactive})
    ) 0 0 no-repeat;
  margin: 5px 0;
  border-radius: 4px;
  padding-right: 10px;
`;
