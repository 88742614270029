import { Alert, Heading, Sizes } from '@sede-x/shell-ds-react-framework';
import { useEffect, useState } from 'react';
import StepperHeader from '../../../AdminFormCreatePage/components/StepperHeader/StepperHeader';
import ConfigurationSection from '../../../AdminFormCreatePage/sections/ConfigurationSection/ConfigurationSection';
import GovernanceSection from '../../../AdminFormCreatePage/sections/GovernanceSection/GovernanceSection';
import ProductDetailsSection from '../../../AdminFormCreatePage/sections/ProductDetailsSection/ProductDetailsSection';
import DeploymentSection from '../../../AdminFormCreatePage/sections/DeploymentSection/DeploymentSection';
import {
  Container,
  ErrorText,
  ErrorSpan,
} from '../../../AdminFormCreatePage/sections/CreationForms/common/AdminFormCreateSection.styles';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes/routes';
import {
  DataProductVariant,
  ProductVariant,
  productTypes,
} from '../../../../../utils/dataProductTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import LoadingFullPageComponent from '../../../../../components/LoadingFullPageComponent/LoadingFullPageComponent';
import { useTranslation } from 'react-i18next';
import { DataProductAdminType } from '../../../../../state/models/adminModel/types';
import PropertiesSection from '../../../AdminFormCreatePage/sections/PropertiesSection/PropertiesSection';
import { areAllParamsEqual } from '../../../../../utils/comparingFunctions';
import LinkWithIcon from '../../../../../components/LinkWithIcon/LinkWithIcon';
import ProductDetailsSectionGlass from '../../../AdminFormCreatePage/sections/ProductDetailsSection/ProductDetailsSectionGlass';
import GovernanceSectionGlass from '../../../AdminFormCreatePage/sections/GovernanceSection/GovernanceSectionGlass';
import { GlassProductAdminType } from '../../../../../state/models/adminModelGlass/types';

type Props = {
  product: DataProductAdminType | GlassProductAdminType;
};

const typeToVariant: { [key: string]: ProductVariant } = {
  api: 'API',
  event: 'Event',
  streaming: 'Streaming',
  resource: 'Resource',
  batch: 'Batch',
  Glass: 'Glass',
};

const requireVersionChange = (
  currentData: DataProductAdminType,
  newData: DataProductAdminType,
) => {
  if (currentData.type === 'batch') {
    return !areAllParamsEqual(currentData, newData, [
      'url',
      'dataVolume',
      'batchSize',
      'numberOfPages',
      'batchFrequency',
      'dataSource',
    ]);
  }
  return (
    JSON.stringify(currentData.endpoints) !==
      JSON.stringify(newData.endpoints) ||
    !areAllParamsEqual(currentData, newData, [
      'uri',
      'topicName',
      'retentionTime',
      'pollTime',
    ])
  );
};

const AdminFormEditSection = ({ product }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);

  const { currentError, isLoading } = useSelector(
    (state: RootState) => state.admin,
  );

  const { navigationHistory } = useSelector(
    (state: RootState) => state.user.user,
  );

  const [error, setError] = useState('');

  useEffect(() => () => dispatch.admin.cleanError(), []);

  const [currentValues, setCurrentValues] = useState<{
    [key: string]: unknown;
  }>(product);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const mandatoryFieldsByProductType: { [key: string]: string[] } = {
    resource: ['businessName', 'description', 'terms', 'url'],
    api: ['businessName', 'description', 'terms'],
    event: ['businessName', 'description', 'terms'],
    batch: ['businessName', 'description', 'terms'],
    streaming: ['businessName', 'description', 'terms'],
  };

  let mandatoryFields: string[] = [];
  mandatoryFields =
    mandatoryFieldsByProductType[currentValues.type as string] || [];

  const checkAllMandatoryFieldsFilled = (values: {
    [key: string]: unknown;
  }) => {
    return [...mandatoryFields].every((field) => {
      if (!(field in values)) {
        return allFieldsFilled;
      } else if (
        values[field] === undefined ||
        values[field] === null ||
        values[field] === '' ||
        (Array.isArray(values[field] as unknown[]) &&
          (values[field] as unknown[]).length === 0)
      ) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    const allMandatoryFieldsFilled = checkAllMandatoryFieldsFilled(
      currentValues as { [key: string]: unknown },
    );
    setAllFieldsFilled(allMandatoryFieldsFilled);
    if (!allMandatoryFieldsFilled) {
      setError('Please fill all mandatory fields');
    } else {
      setError('');
    }
  }, [currentValues, mandatoryFields]);

  const type =
    typeToVariant[
      (currentValues.type as keyof typeof typeToVariant) || productTypes.Glass
    ];

  const navigate = useNavigate();
  const stepUp = () => setCurrentStep(currentStep + 1);
  const stepBack = () => setCurrentStep(currentStep - 1);

  const saveAndGoAhead = (values: { [key: string]: unknown }) => {
    const allMandatoryFieldsFilled = checkAllMandatoryFieldsFilled(values);
    if (allMandatoryFieldsFilled) {
      setCurrentValues({ ...currentValues, ...values });
      stepUp();

      setError('');
    } else {
      setError('Please fill out all mandatory fields.');
    }
  };
  const submit = async (values: { [key: string]: unknown }) => {
    setCurrentValues({ ...currentValues, ...values });
    const { newVersion, ...newValues }: { [x: string]: unknown } = {
      ...currentValues,
      ...values,
    };
    const submitValues = {
      ...newValues,
      type,
      version: newVersion ? newVersion : newValues.version,
      overrideVersion: !newVersion || newVersion === newValues.version,
    };

    let response;
    if (type === productTypes.Glass) {
      dispatch.adminGlass.cleanError();
      response = await dispatch.adminGlass.updateGlassProduct(newValues);
    } else {
      dispatch.admin.cleanError();
      response = await dispatch.admin.updateDataProduct(submitValues);
    }

    if (response < 300) {
      navigate(routes.updateSuccess, {
        state: {
          versionChanged: !!newVersion,
          dataProductName: (product as DataProductAdminType).name,
          version: newVersion || currentValues.version,
          createdType: type,
        },
      });
    }
    if (response >= 500) {
      navigate(routes.error, {
        state: { isUpdating: true, createdType: type },
      });
    }
  };

  const firstElementProps = {
    onSubmit: saveAndGoAhead,
    onCancel: () => {
      if (navigationHistory.type === 'details') {
        navigate(`${routes.details}/${navigationHistory.item}`);
      } else {
        type === productTypes.Glass
          ? navigate(`${routes.admin}${routes.adminGlassHubRelative}`)
          : navigate(routes.create);
      }
    },
  };

  const elementProps = {
    onSubmit: saveAndGoAhead,
    onCancel: stepBack,
    defaultValues: currentValues,
    type: type as DataProductVariant,
    isUpdating: true,
  };
  const lastElementProps = { onSubmit: submit, onCancel: stepBack };

  const variant = (defaultValues: { [key: string]: unknown }) => {
    if (defaultValues.id) {
      const elementEditProps = { ...elementProps, defaultValues };
      const requireNewVersion =
        type !== productTypes.Glass &&
        requireVersionChange(
          product as DataProductAdminType,
          currentValues as DataProductAdminType,
        );

      return {
        API: [
          <ProductDetailsSection
            {...elementEditProps}
            {...firstElementProps}
            key={'ProductDetailsSection'}
            nameEditable={false}
            showName={true}
          />,
          <ConfigurationSection
            {...elementEditProps}
            key={'ConfigurationSection'}
          />,
          <GovernanceSection {...elementEditProps} key={'GovernanceSection'} />,
          <DeploymentSection
            {...elementEditProps}
            {...lastElementProps}
            defaultValues={currentValues}
            requireNewVersion={requireNewVersion}
          />,
        ],
        Event: [
          <ProductDetailsSection
            {...elementEditProps}
            {...firstElementProps}
            key={'ProductDetailsSection'}
            nameEditable={false}
            showName={true}
          />,
          <ConfigurationSection
            {...elementEditProps}
            key={'ConfigurationSection'}
          />,
          <GovernanceSection
            {...elementEditProps}
            {...lastElementProps}
            key={'GovernanceSection'}
            requireNewVersion={requireNewVersion}
          />,
        ],
        Streaming: [
          <ProductDetailsSection
            {...elementEditProps}
            {...firstElementProps}
            key={'ProductDetailsSection'}
            nameEditable={false}
            showName={true}
          />,
          <ConfigurationSection
            {...elementEditProps}
            key={'ConfigurationSection'}
          />,
          <GovernanceSection
            {...elementEditProps}
            {...lastElementProps}
            key={'GovernanceSection'}
            requireNewVersion={requireNewVersion}
          />,
        ],
        Resource: [
          <ProductDetailsSection
            {...elementEditProps}
            {...firstElementProps}
            key={'ProductDetailsSection'}
            nameEditable={false}
            showName={true}
          />,
          <GovernanceSection
            {...elementEditProps}
            {...lastElementProps}
            key={'GovernanceSection'}
            requireNewVersion={false}
          />,
        ],
        Batch: [
          <ProductDetailsSection
            {...elementEditProps}
            {...firstElementProps}
            key={'ProductDetailsSection'}
            nameEditable={false}
            showName={true}
          />,
          <PropertiesSection {...elementEditProps} key={'PropertiesSection'} />,
          <GovernanceSection
            {...elementEditProps}
            {...lastElementProps}
            key={'GovernanceSection'}
            requireNewVersion={requireNewVersion}
          />,
        ],
        Glass: [
          <ProductDetailsSectionGlass
            {...elementEditProps}
            {...firstElementProps}
            key={'ProductDetailsSection'}
          />,
          <GovernanceSectionGlass
            {...elementEditProps}
            {...lastElementProps}
            key={'GovernanceSection'}
          />,
        ],
      };
    }

    return {
      API: [],
      Event: [],
      Streaming: [],
      Resource: [],
      Batch: [],
      Glass: [],
    };
  };

  const contents = variant(currentValues)[type];
  const title =
    type !== productTypes.Glass
      ? t('admin_update-product_main_title', { type })
      : t('admin_update-product_main_title_glass');

  return (
    <Container>
      <Heading bold>{title}</Heading>
      <StepperHeader
        currentStep={currentStep}
        onChangeStep={(newStep: number) => setCurrentStep(newStep)}
        type={type}
      />
      {error && (
        <ErrorText>
          <ErrorSpan>{error}</ErrorSpan>
        </ErrorText>
      )}
      {currentError && (
        <Alert sentiment="negative" size={Sizes.Large}>
          {currentError}
        </Alert>
      )}
      {contents?.[currentStep]}
      {type !== productTypes.Glass && (
        <LinkWithIcon href={t('admin_create-product_main_link')}>
          {t('admin_create-product_main_link_text')}
        </LinkWithIcon>
      )}
      {isLoading && <LoadingFullPageComponent />}
    </Container>
  );
};

export default AdminFormEditSection;
