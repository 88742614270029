import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';

export const useFetchDataDomain = () => {
  const dispatch = useDispatch();
  const {
    items: dataDomains,
    loading,
    error,
  } = useSelector((state: RootState) => state.dataDomains);

  useEffect(() => {
    if (!dataDomains || dataDomains?.length === 0) {
      dispatch.dataDomains.fetchDataDomains();
    }
  }, [dispatch, dataDomains]);

  return { dataDomains, loading, error };
};
