import React from 'react';
import { DownloadContainer } from './DownloadButton.style';
import DataProductService from '../../../../../../../services/DataProductService';
import ActionButton from '../../../../../../../components/ActionButton/ActionButton';

import { Icons } from '@sede-x/shell-ds-react-framework';

interface Props {
  name: string;
}

interface Response {
  fileContent: string;
  fileName: string;
  fileFormat: 'json' | 'csv';
}

const DownloadButton = ({ name }: Props) => {
  const getResource = async (dPname: string) => {
    return DataProductService.getResourceFile(dPname);
  };

  const processAndDownload = (response: Response) => {
    try {
      if (!response?.fileContent) {
        return;
      }

      const fileName = response.fileName || 'data.' + response.fileFormat;
      let blob: Blob;

      if (response.fileFormat === 'json') {
        const jsonData = response.fileContent;
        blob = new Blob([jsonData], { type: 'application/json' });
      } else if (response.fileFormat === 'csv') {
        const csvContent = response.fileContent;
        blob = new Blob([csvContent], { type: 'text/csv' });
      } else {
        return;
      }

      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    } catch (error) {
      return;
    }
  };

  const handleDownload = async () => {
    const response = await getResource(name);
    processAndDownload(response);
  };

  return (
    <DownloadContainer>
      <ActionButton
        onClick={handleDownload}
        style={{
          marginLeft: '-5px',
          marginBottom: '-2px',
        }}
        data-testid="download-button"
        icon={<Icons.Download width={24} height={24} />}
      />
    </DownloadContainer>
  );
};

export default DownloadButton;
