import { useEffect, useState } from 'react';
import List from '../../components/List/List';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import LoadingList from '../../components/LoadingList/LoadingList';
import { ErrorState } from '../../components/ErrorState/ErrorState';
import { ListContainer, ListSectionContainer } from './ListComponent.styles';
import useParamsHandler, { FilterNames } from '../../hooks/useParamsHandler';
import LoadingRowCard from '../../components/LoadingRowCard/LoadingRowCard';
import { SelectedView } from '../FiltersComponent/FiltersComponent';

export const getParamsList = (type: FilterNames, params: string[]) => {
  return { id: type, values: params || [] };
};

interface FiltersComponentProps {
  selectedView: SelectedView;
}

const ListComponent: React.FC<FiltersComponentProps> = ({ selectedView }) => {
  const { params } = useParamsHandler();
  const [page, setPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem('rowsPerPage')) || 10,
  );
  const dispatch = useDispatch();

  const { dataProducts, totalItems, isLoading, hasError } = useSelector(
    (state: RootState) => state.dataProducts,
  );

  useEffect(() => {
    dispatch.dataProducts.getDomains();
    dispatch.dataProducts.getTags();
  }, []);

  useEffect(() => {
    setPage(1);
    dispatch.dataProducts.getDataProducts({
      keywords: params.search?.[0] || '',
      offset: 0,
      limit: rowsPerPage,
      filters: [
        getParamsList('type', params.type),
        getParamsList('terms', params.terms),
        getParamsList('tags', params.tags),
      ],
    });
  }, [dispatch.dataProducts, params, rowsPerPage]);

  const onPageChange = (newPage: number) => {
    dispatch.dataProducts.getDataProducts({
      keywords: params.search?.[0] || '',
      offset: (newPage - 1) * rowsPerPage,
      limit: rowsPerPage,
      filters: [
        getParamsList('type', params.type),
        getParamsList('terms', params.terms),
        getParamsList('tags', params.tags),
      ],
    });
    setPage(newPage);
  };

  const onPaginationChange = (pageSize: number) => {
    localStorage.setItem('rowsPerPage', pageSize.toString());
    setRowsPerPage(pageSize);
  };

  if (isLoading) {
    return (
      <ListSectionContainer>
        <ListContainer>
          {selectedView === 'List' ? (
            <LoadingList />
          ) : (
            <LoadingRowCard numRows={rowsPerPage} />
          )}
        </ListContainer>
      </ListSectionContainer>
    );
  }
  if (hasError) {
    return (
      <ListSectionContainer>
        <ListContainer>
          <ErrorState />
        </ListContainer>
      </ListSectionContainer>
    );
  }

  return (
    <ListSectionContainer>
      <ListContainer>
        <List
          dataProducts={dataProducts}
          searchText={params.search?.[0]}
          totalItems={totalItems}
          page={page}
          maxRowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onPaginationChange={onPaginationChange}
          selectedView={selectedView}
        />
      </ListContainer>
    </ListSectionContainer>
  );
};

export default ListComponent;
