type Option = {
  value: number;
  label: string;
};

export const generateOptions = (numbers: number[]): Option[] => {
  return numbers.map((num) => ({
    value: num,
    label: num.toString(),
  }));
};
