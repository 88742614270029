const routes = {
  admin: '/admin',
  adminGlassHubRelative: '/glass',
  create: '/admin/create',
  createApi: '/admin/create/api',
  createEvent: '/admin/create/event',
  createStreaming: '/admin/create/streaming',
  createResource: '/admin/create/resource',
  createBatch: '/admin/create/batch',
  createGlassHub: '/admin/create/glasshub-product',
  edit: '/admin/edit',
  editGlassHub: '/admin/edit/glasshub',
  success: '/admin/success',
  updateSuccess: '/admin/success-update',
  error: '/admin/error',
  insights: '/insights',
  mySubscriptions: '/mySubscriptions',
  datamap: '/datamap',
  home: '/',
  details: '/product',
};

export default routes;
